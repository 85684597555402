import zxcvbn from 'zxcvbn';
import validator from 'validator';
import EventBus from "eventing-bus";
import { connect } from "react-redux";
import React, { Component } from "react";

import "./index.css";
import "../../static/css/animate.css";
import "owl.carousel/dist/assets/owl.carousel.css";

import Navbar from "../../components/navbar";
import { toggleLoader } from "../../store/actions/Auth";
import { withRouter } from 'react-router-dom';

class SelectCategory extends Component {

  constructor(props) {
    super(props);
    this.state = {
      sticky: false,
      setQuiz:"",
    };
  }

  onScroll = () => {
    const { pageYOffset } = window;
    if (pageYOffset > 20) this.setState({ sticky: true });
    if (pageYOffset < 20) this.setState({ sticky: false });
  };

  proceed = (e) => {
    try {
      e.preventDefault();

      let { setQuiz } = this.state;
      const { history } = this.props;

      if (setQuiz == "") {
        EventBus.publish("error", `Please select category!`);
        return;
      }

      history.push(`/quiz/${setQuiz}`);

    } catch (error) {
      EventBus.publish("error", `Could not proceed to quiz!`);
      console.log("error", error)
      return;
    }
  }

  componentDidUpdate(prevProps){
    if(prevProps.quizStatus !== this.props.quizStatus){
      if(this.props.quizStatus == "pass") this.props.history.push("/course")
    }
    if(prevProps.categories !== this.props.categories){
      if(this.props.categories.length > 0) this.setState({setQuiz:this.props.categories[0]['_id']})
    }
  }

  componentDidMount(){
    if(this.props.categories.length > 0) this.setState({setQuiz:this.props.categories[0]['_id']})
  }

  render() {
    let { setQuiz, sticky } = this.state;
    let {categories} = this.props;
    return (
      <div className="wrapper" onWheel={this.onScroll}>
        <Navbar sticky={sticky} />

        <section class="form-main sign-up">
          <div class="wrap">
            <div class="inner">
              <h1>Select Category</h1>

              <form onSubmit={this.proceed}>
                <div class="form-group">
                  <p>You are going to perform a quiz to get certified on the platform to teach courses kindly select area of your interest</p>
                  <br />
                  <select class="form-control" value={setQuiz} onChange={e=>this.setState({setQuiz:e.target.value})}>
                      {categories && categories.length>0 && 
                      categories.map(items=>
                        <option value={items['_id']}>{items['categoryName']}</option>
                      )
                      }
                  </select>
                </div>

                <div class="form-group">
                  <input type="submit" value="Submit" name="submit" />
                </div>
              </form>
            </div>
          </div >
        </section >
      </div >
    );
  }
}

const mapDispatchToProps = { toggleLoader };

const mapStateToProps = ({ Auth }) => {
  let { auth, categories,quizStatus} = Auth;
  return { auth, categories,quizStatus };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SelectCategory));
