import $ from 'jquery';
import EventBus from "eventing-bus";
import { connect } from "react-redux";
import { Veriff } from '@veriff/js-sdk';
import { HashLink } from "react-router-hash-link";
import React, { useEffect, useState } from "react";
import { validate } from "wallet-address-validator";

import "./index.css";
import "../../static/css/animate.css";

import { web3 } from "../../store/web3";
// import Navbar from "../../components/navbar";
import { networkId, message } from "../../store/config";
import { toggleLoader, kycSubmit, getKycStatus, setAddress } from "../../store/actions/Auth";

function VerifyKYC(props) {

  const [sticky, setSticky] = useState(false);

  useEffect(() => {
    if (props.kycStatus == false && props.publicAddress) {
      veriffStart();
      $(".veriff-container").prepend(`<label class="veriff-label" id="veriff-label-given-username" htmlfor="givenUsername">Username</label><input type="text" class="veriff-text" id="veriff-given-username" name="givenUsername" required="" placeholder="Username">`);
      $(".veriff-container").prepend(`<label class="veriff-label" id="veriff-label-given-email" htmlfor="givenEmail">Email</label><input type="email" class="veriff-text" id="veriff-given-email" name="givenEmail" required="" placeholder="Email">`);
      $(".veriff-container").prepend(`<label class="veriff-label" id="veriff-label-given-linkedin" htmlfor="givenLinkedin">Linkedin</label><input type="text" class="veriff-text" id="veriff-given-linkedin" name="givenLinkedin" required="" placeholder="Linkedin">`);
      var veriffVendorDataInput = document.getElementById("veriff-vendor-data");
      // Check if the element exists before attempting to set its value
      if (veriffVendorDataInput) {
        // Set the value of the input field
        veriffVendorDataInput.value = props.publicAddress.toLowerCase();
      }
    }
    else if (props.kycStatus == "expired" && props.publicAddress) {
      // else if (props.kycStatus == "expired" && props.publicAddress && isWhitelist == true) {
      veriffStart();
      $(".veriff-container").prepend(`<label class="veriff-label" id="veriff-label-given-username" htmlfor="givenUsername">Username</label><input type="text" class="veriff-text" id="veriff-given-username" name="givenUsername" required="" placeholder="Username">`);
      $(".veriff-container").prepend(`<label class="veriff-label" id="veriff-label-given-email" htmlfor="givenEmail">Email</label><input type="email" class="veriff-text" id="veriff-given-email" name="givenEmail" required="" placeholder="Email">`);
      $(".veriff-container").prepend(`<label class="veriff-label" id="veriff-label-given-linkedin" htmlfor="givenLinkedin">Linkedin</label><input type="text" class="veriff-text" id="veriff-given-linkedin" name="givenLinkedin" required="" placeholder="Linkedin">`);
      var veriffVendorDataInput = document.getElementById("veriff-vendor-data");
      // Check if the element exists before attempting to set its value
      if (veriffVendorDataInput) {
        // Set the value of the input field
        veriffVendorDataInput.value = props.publicAddress.toLowerCase();
      }
    }
  }, [props.kycStatus, props.publicAddress]);

  const getNFT = async () => {
    let deployer = (await web3.currentProvider.enable())[0];
    if (deployer == null || deployer == undefined) {
      EventBus.publish("error", `Please connect your wallet!`);
      return;
    }
    props.setAddress(deployer);
    props.toggleLoader({ message: "Loading...", status: true });
    props.getKycStatus({ publicAddress: deployer });
  }

  useEffect(() => {
    getNFT();
  }, []);

  const veriffStart = async () => {
    const waitFor = (delay) =>
      new Promise((resolve) => setTimeout(resolve, delay));
    const veriff = Veriff({
      host: 'https://stationapi.veriff.com',
      apiKey: "a0e8c14a-9f3b-44a6-a45b-30527ef597e3",
      parentId: 'veriff-root',
      onSession: async function (err, response) {
        await waitFor(1500);
        let email = document.getElementById('veriff-given-email').value;
        let linkedin = document.getElementById('veriff-given-linkedin').value;
        let username = document.getElementById('veriff-given-username').value;
        if (!email) return EventBus.publish("error", `Please enter valid email!`);
        if (!linkedin) return EventBus.publish("error", `Please enter valid linkedIn!`);
        if (!username) return EventBus.publish("error", `Please enter valid username!`);
        let id = response.verification.id;
        let vendorData = response.verification.vendorData;
        let url = response.verification.url;
        let status = response.verification.status;
        let session = response.verification.sessionToken;
        vendorData = vendorData.toLowerCase();
        if (id && vendorData && url && status && session) {
          props.kycSubmit({ id, email, linkedin, username, vendorData, url, status, session });
          await waitFor(1500);
          window.location.href = response.verification.url;
        }
      }
    });
    veriff.mount({
      formLabel: {
        givenName: 'First Name',
        lastName: 'Last Name',
        vendorData: 'Metamask Wallet Address'
      },
    });
  }

  const onScroll = () => {
    const { pageYOffset } = window;
    if (pageYOffset > 20) setSticky(true);
    if (pageYOffset < 20) setSticky(false);
  };

  return (
    <div className="wrapper" onWheel={onScroll}>
      {/* <Navbar /> */}

      <div className="admin-wrapper">

        <div className="inner verify-wrap">
          <div className="form-main verify sign-up">
            <div className="inner">

              <h1>Verify KYC</h1>

              {props.kycStatus == false &&
                <>
                  <div className="veriff-wrap">
                    <div id='veriff-root' className="edit-add-body col-12"></div>
                  </div>
                </>
              }

              {props.kycStatus == "expired" &&
                <>
                  <div className="veriff-wrap">
                    <div id='veriff-root' className="edit-add-body col-12"></div>
                  </div>
                </>
              }

              {props.kycStatus == "abandoned" &&
                <>
                  <div className="veriff-wrap">
                    <div id='veriff-root' className="edit-add-body col-12"></div>
                  </div>
                </>
              }

              {props.kycStatus == "resubmission_requested" &&
                <>
                  <div className="veriff-wrap">
                    <div id='veriff-root' className="edit-add-body col-12"></div>
                  </div>
                </>
              }

              {props.kycStatus == "declined" &&
                <>
                  <div className="veriff-wrap">
                    <div id='veriff-root' className="edit-add-body col-12"></div>
                  </div>
                </>
              }

              {props.kycStatus == "review" &&
                <>
                  <div className="veriff-wrap">
                    <div id='veriff-root' className="edit-add-body col-12"></div>
                  </div>
                </>
              }

              {props.kycStatus == "created" &&
                <p className="text-center">Verification Already Submitted, It will take 24Hrs to process your verification.</p>
              }

              {props.kycStatus == "approved" &&
                <p className="text-center">Verification Already Approved</p>
              }
              <p>Already have an account? <a href="/sign-in">Sign in</a></p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const mapDispatchToProps = { toggleLoader, kycSubmit, getKycStatus, setAddress };

const mapStateToProps = ({ Auth }) => {
  let { publicAddress, userStatus, availableCourses, kycStatus } = Auth;
  return { publicAddress, userStatus, availableCourses, kycStatus };
};

export default connect(mapStateToProps, mapDispatchToProps)(VerifyKYC);
