import EventBus from "eventing-bus";
import { connect } from "react-redux";
import Countdown from "react-countdown";
import OwlCarousel from "react-owl-carousel";
import React, { Component, Fragment } from "react";
import { validate } from "wallet-address-validator";
import { HashLink } from "react-router-hash-link";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";

import "./index.css";
import "../../static/css/animate.css";
import "owl.carousel/dist/assets/owl.carousel.css";

import { web3 } from "../../store/web3";
import Navbar from "../../components/navbar";
import Footer from "../../components/footer";
import { networkId, message } from "../../store/config";
import { toggleLoader, sendResult } from "../../store/actions/Auth";
import { ICO, ICOAddress } from "../../store/contract/index";

class Learn2Earn extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sticky: false,
      quizState: false,
      quantity: 0,
      value1: "",
      value2: "",
      value3: "",
      value4: "",
      value5: "",
      address: localStorage.getItem("publicAddress"),
    };
  }

  async componentDidMount() {
    let { publicAddress } = this.props;
    this.setState({ address: publicAddress });
  }

  componentWillReceiveProps({ publicAddress }) {
    this.setState({ address: publicAddress });
  }

  handleQuantity = () => {
    let { address } = this.state;
    if (address == "" || address == null) {
      EventBus.publish("error", `Connect to Metamask`);
      return;
    }
  };

  onScroll = () => {
    const { pageYOffset } = window;
    if (pageYOffset > 20) this.setState({ sticky: true });
    if (pageYOffset < 20) this.setState({ sticky: false });
  };

  handleChange = (e) => {
    if (e.target.name == "value1") {
      this.setState({ value1: e.target.value });
    } else if (e.target.name == "value2") {
      this.setState({ value2: e.target.value });
    } else if (e.target.name == "value3") {
      this.setState({ value3: e.target.value });
    } else if (e.target.name == "value4") {
      this.setState({ value4: e.target.value });
    } else if (e.target.name == "value5") {
      this.setState({ value5: e.target.value });
    }
  };

  submitAssessment = () => {
    let { value1, value2, value3, value4, value5 } = this.state;
    let { publicAddress } = this.props;
    if (publicAddress == null) {
      EventBus.publish("error", `Please connect your wallet!`);
      return;
    }
    let count = 0;
    if (value1 == "true") {
      count += 1;
    }
    if (value2 == "true") {
      count += 1;
    }
    if (value3 == "true") {
      count += 1;
    }
    if (value4 == "true") {
      count += 1;
    }
    if (value5 == "true") {
      count += 1;
    }
    if (count >= 4) {
      console.log("**** count", count);
      this.props.sendResult({ publicAddress, status: true });
      this.setState({ quizState: false });
    } else {
      this.props.sendResult({ publicAddress, status: false });
      this.setState({ quizState: false });
    }
  }

  quiz = () => {
    this.setState({ quizState: true });
  }

  render() {
    let { value1, value2, value3, value4, value5, sticky, quizState } = this.state;
    return (
      <div className="wrapper" onWheel={this.onScroll}>
        <Navbar sticky={sticky} />

        <section class="form-main sign-up">
            <div class="wrap">
                <div class="inner">
                    <h1>Sign up</h1>
                    <form>
                        
                        <div class="form-group">
                            <input type="text" class="form-control" placeholder="Name"/>
                        </div>

                        <div class="form-group">
                            <input type="email" class="form-control" placeholder="Email"/>
                        </div>
                        
                        <div class="form-group">
                            <input type="password" class="form-control" placeholder="Password"/>
                        </div>

                        <div class="form-group">
                            <input type="password" class="form-control" placeholder="Retype password"/>
                        </div>

                        <div class="form-group">
                            <input type="submit" value="Sign up" name="submit"/>
                        </div>
                    </form>
                    <p>Already have an account? <a href="/sign-in">Sign in</a></p>
                </div>
            </div>
        </section>
      </div>
    );
  }
}

const mapDispatchToProps = { toggleLoader, sendResult };

const mapStateToProps = ({ Auth }) => {
  let { publicAddress } = Auth;
  return { publicAddress };
};

export default connect(mapStateToProps, mapDispatchToProps)(Learn2Earn);
