import EventBus from "eventing-bus";
import { connect } from "react-redux";
import React, { Component, Fragment } from "react";
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';

import "./index.css";
import "../../static/css/animate.css";
import "owl.carousel/dist/assets/owl.carousel.css";

import { web3 } from "../../store/web3";
import Navbar from "../../components/navbar";
import { toggleLoader, sendResult, getSuperQuiz, getSuperQuestions, getKycStatus, setAddress } from "../../store/actions/Auth";
import { AdminSoulBoundABI, AdminSoulBoundBytecode } from "../../store/contract/index";
import { Link } from "react-router-dom";
const certificate = "/images/certified-trainer.png";

class QuizQuestion extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sticky: false,
      quizDetails: {},
      quizQuestions: [],
      qCount: 1,
      question: "",
      answer1: "",
      answer2: "",
      answer3: "",
      answer4: "",
      selectedAnswer: "",
      correctAnswer: "",
      answers: [],
      status: false,
      publicAddress: localStorage.getItem("publicAddress"),
      certificateModal: false
    };
  }

  async componentDidMount() {
    this.props.toggleLoader({ status: true, message: "Loading..." });
    let deployer = (await web3.currentProvider.enable())[0];
    if (deployer == null || deployer == undefined) {
      EventBus.publish("error", `Please connect your wallet!`);
      return;
    }
    this.props.setAddress(deployer);
    this.props.getSuperQuiz(this.props.match.params.category);
    this.props.getKycStatus({ publicAddress: deployer });
  }

  setQuizList = async (quizQuestions) => {
    this.setState({ quizQuestions });
    this.setState({
      quizQuestions: quizQuestions,
      qCount: 1,
      question: quizQuestions[0]['question'],
      answer1: quizQuestions[0]['answerA'],
      answer2: quizQuestions[0]['answerB'],
      answer3: quizQuestions[0]['answerC'],
      answer4: quizQuestions[0]['answerD'],
      correctAnswer: quizQuestions[0]['correctAnswer'],
    });
    this.props.toggleLoader({ status: false, message: "Loading..." });
  }

  async componentDidUpdate(prevProps) {
    const { match, superQuestionsList, superQuizList } = this.props;
    if (prevProps.superQuizList !== superQuizList) {
      if (this.props.superQuizList.length > 0) {
          this.setState({ quizDetails: superQuizList[0] });
          this.setQuizList(superQuizList)
      } else {
        this.props.toggleLoader({ status: false, message: "Loading..." });
      }
    }
  }


  handleChange = (qCount, answer) => {
    let { answers } = this.state
    let checkQuestion = answers.some(item => item['question'] == qCount);
    if (!checkQuestion) {
      answers.push({
        question: qCount,
        answer: answer,
        selectedAnswer: answer
      })
      this.setState({
        answers,
        selectedAnswer: answer
      });
    }
    else {
      answers.find(item => {
        if (item['question'] == qCount) {
          item['answer'] = answer;
          item['selectedAnswer'] = answer;
        }
        return item;
      });
      this.setState({
        answers,
        selectedAnswer: answer
      });
    }
  };

  handleLeft = (idx) => {
    let { quizQuestions, answers } = this.state;
    idx = parseInt(idx);
    this.setState({
      qCount: idx - 1,
      question: quizQuestions[idx - 2]['question'],
      answer1: quizQuestions[idx - 2]['answerA'],
      answer2: quizQuestions[idx - 2]['answerB'],
      answer3: quizQuestions[idx - 2]['answerC'],
      answer4: quizQuestions[idx - 2]['answerD'],
      correctAnswer: quizQuestions[idx - 2]['correctAnswer'],
      selectedAnswer: answers[idx - 2] ? answers[idx - 2]['selectedAnswer'] : ""
    });
  };

  handleRight = (idx) => {
    let { quizQuestions, answers} = this.state;
    idx = parseInt(idx);
    this.setState({
      qCount: idx + 1,
      question: quizQuestions[idx]['question'],
      answer1: quizQuestions[idx]['answerA'],
      answer2: quizQuestions[idx]['answerB'],
      answer3: quizQuestions[idx]['answerC'],
      answer4: quizQuestions[idx]['answerD'],
      correctAnswer: quizQuestions[idx]['correctAnswer'],
      selectedAnswer: answers[idx] ? answers[idx]['selectedAnswer'] : ""
    });
  };

  submitAssessment = async () => {
    try {
      let { publicAddress, quizQuestions, quizDetails, answers } = this.state;
      let { userData, kycValue } = this.props;

      userData = JSON.parse(userData);

      if (publicAddress == null || publicAddress == undefined) {
        EventBus.publish("error", `Please connect your wallet!`);
        return;
      }

      if (quizQuestions.length !== answers.length) {
        EventBus.publish("error", `You have not asnwered all questions!`);
        return;
      }
      let count = quizQuestions.filter((item, index) => item.correctAnswer === answers[index].answer).length;
      if (((count / quizQuestions.length) * 100) > parseInt(quizDetails.quiz.setPassingPercentage)) {
        this.props.toggleLoader({
          message: "Deploying Soul Bound Token...",
          status: true,
        });

        let contract = new web3.eth.Contract(AdminSoulBoundABI);
        let deploy = contract.deploy({
          data: AdminSoulBoundBytecode, arguments: [
            publicAddress,
            kycValue && kycValue['firstName'] ? kycValue['firstName'] : " ",
            kycValue && kycValue['lastName'] ? kycValue['lastName'] : " ",
            kycValue && kycValue['fullAddress'] ? kycValue['fullAddress'] : " ",
            kycValue && kycValue['gender'] ? kycValue['gender'] : " ",
            kycValue && kycValue['nationality'] ? kycValue['nationality'] : " ",
            kycValue && kycValue['dateOfBirth'] ? kycValue['dateOfBirth'] : " ",
            kycValue && kycValue['documentType'] ? kycValue['documentType'] : " ",
            kycValue && kycValue['idNumber'] ? kycValue['idNumber'] : " ",
          ]
        });

        await deploy.send({ from: publicAddress })
          .on('transactionHash', hash => console.log(`************** deploy contract hash = ${hash}`))
          .on('receipt', async receipt => {
            this.props.toggleLoader({
              message: "Deploying Soul Bound Token...",
              status: false,
            });

            await this.props.sendResult({ publicAddress, quizId: quizDetails.quiz['_id'], status: "pass", user: userData['userId'], soulbound: receipt['contractAddress'] });
            
            this.setState({ certificateModal: true });

            EventBus.publish("success", `Deployed: ${receipt['contractAddress']}`);
            console.log(`************** deploy contract address = `, receipt['contractAddress'])
          });
      } 
      else return EventBus.publish("error", `You are fail`);
    }
    catch (e) {
      console.log(e);
      this.props.toggleLoader({ status: false, message: "Deploying Soul Bound Token..." });
      EventBus.publish("error", e.message);
      return;
    }
  }


  render() {
    let { qCount, quizQuestions, question, answer1, answer2, answer3, answer4, selectedAnswer, sticky} = this.state;
    let { publicAddress, kycValue, kycStatus } = this.props;

    return (
      <div className="wrapper">
        <Navbar sticky={sticky} />

        <div class="quizarea">
          <div class="container">
            {
              quizQuestions && quizQuestions.length > 0 &&
              <>
                {
                  qCount !== 1 &&
                  <a href="#" class="arrow-left arrows" onClick={() => this.handleLeft(qCount)}><i class="fa fa-angle-left" aria-hidden="true"></i></a>
                }
                {
                  <div class="quiz-inner">
                    {
                      <>
                        <div class="question-wrapper">
                          <span>Question # {qCount} of {quizQuestions.length}</span>
                          <p>{question}</p>
                        </div>
                        <div class="answer-option">
                          <button name={answer1} value={answer1} onClick={() => this.handleChange(qCount, answer1)} className={`${selectedAnswer}` == `${answer1}` ? "option answer" : "option"}><span>A</span> {answer1}</button>
                          <button name={answer2} value={answer2} onClick={() => this.handleChange(qCount, answer2)} className={`${selectedAnswer}` == `${answer2}` ? "option answer" : "option"}><span>B</span> {answer2}</button>
                          <button name={answer3} value={answer3} onClick={() => this.handleChange(qCount, answer3)} className={`${selectedAnswer}` == `${answer3}` ? "option answer" : "option"}><span>C</span> {answer3}</button>
                          <button name={answer4} value={answer4} onClick={() => this.handleChange(qCount, answer4)} className={`${selectedAnswer}` == `${answer4}` ? "option answer" : "option"}><span>D</span> {answer4}</button>
                        </div>
                        {
                          qCount == quizQuestions.length &&
                          <a href="#" onClick={() => this.submitAssessment()} class="common-btn check-answer">Submit Quiz</a>
                        }
                      </>
                    }
                  </div>
                }
                {
                  qCount !== quizQuestions.length &&
                  <a href="#" class="arrow-right arrows" onClick={() => this.handleRight(qCount)}><i class="fa fa-angle-right" aria-hidden="true"></i></a>
                }
              </>
            }
            {
              quizQuestions && quizQuestions.length == 0 &&
              <>
                {
                  <div class="quiz-inner">
                    <>
                      <div class="question-wrapper">
                        <span></span>
                        <p>No questions available</p>
                      </div>
                      <br />
                      <Link to="/select-category" class="common-btn check-answer">Back</Link>
                    </>
                  </div>
                }
              </>
            }
          </div>
        </div>

        <Modal
          open={this.state.certificateModal}
          onClose={() => this.setState({ certificateModal: false })}
          classNames={{
            modal: `common-modal certificate`,
          }}
          center
        >
          <div class="modal-header">
            <button type="button" class="close" onClick={() => this.setState({ certificateModal: false })}>
              <span aria-hidden="true">&times;</span>
            </button>
          </div>

          <div class="modal-body">
            <div className="img-wrap">
              <img src={certificate}></img>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

const mapDispatchToProps = { toggleLoader, sendResult, getSuperQuiz, getSuperQuestions, getKycStatus, setAddress };

const mapStateToProps = ({ Auth }) => {
  let { publicAddress, kycValue, kycStatus, userData, userStatus, superQuizList, superQuestionsList } = Auth;
  return { publicAddress, kycValue, kycStatus, userData, userStatus, superQuizList, superQuestionsList };
};

export default connect(mapStateToProps, mapDispatchToProps)(QuizQuestion);
