import React, { Component } from 'react';
import { NavLink, Outlet, useLocation } from "react-router-dom";

const Sidebar = () => {

    const location = useLocation();

    return (
        <>
        <div className='sidebar'>
            <ul>
                <li className="nav-item">
                    <NavLink className={location.pathname === '' ? 'active' : ''} to="">
                        <img src="/images/course-icon.png"/>
                        <img className="blue" src="/images/course-icon-blue.png"/>

                        Courses
                    </NavLink>
                </li>

                {/* <li className="nav-item">
                    <NavLink className={location.pathname === '' ? 'active' : ''} to="">
                        <img src="images/verify.png"/>
                        <img className="blue" src="images/verify-blue.png"/>

                        Verify KYC 
                    </NavLink>
                </li> */}
            </ul>

            {/* <ul>
                <li className="nav-item">
                    <NavLink className="logout" to="">
                        <img src="/images/logout.png"/>
                        <img className="blue" src="/images/logout-blue.png"/>
                        
                        Logout
                    </NavLink>
                </li>
            </ul> */}
        </div>
        
        </>
    );
}



export default Sidebar;