import validator from 'validator';
import EventBus from "eventing-bus";
import { connect } from "react-redux";
import React, { Component } from "react";
import { Redirect, withRouter } from "react-router-dom";

import { web3 } from "../../store/web3";

import "./index.css";
import "../../static/css/animate.css";
import "owl.carousel/dist/assets/owl.carousel.css";

import { toggleLoader, login, loginLoader, getKycStatus, setAddress } from "../../store/actions/Auth";

class SignIn extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sticky: false,
      email: "",
      type: 'password',
      password1: "",
    };
    this.showHide = this.showHide.bind(this);
  }

  async componentDidMount() {
    let deployer = (await web3.currentProvider.enable())[0];
    if (deployer == null || deployer == undefined) {
      EventBus.publish("error", `Please connect your wallet!`);
      return;
    }
    this.props.setAddress(deployer);
    // this.props.toggleLoader({ message: "Loading...", status: true });
    this.props.getKycStatus({ publicAddress: deployer });
  }

  showHide(e) {
    e.preventDefault();
    e.stopPropagation();
    this.setState({
      type: this.state.type === 'input' ? 'password' : 'input'
    })
  }

  onScroll = () => {
    const { pageYOffset } = window;
    if (pageYOffset > 20) this.setState({ sticky: true });
    if (pageYOffset < 20) this.setState({ sticky: false });
  };

  handleChange = (e) => {
    if (e.target.name == "email") {
      this.setState({ email: e.target.value });
    } else if (e.target.name == "password1") {
      if (e.target.value === '') {
        this.setState({
          password1: ''
        })
      }
      else {
        this.setState({
          password1: e.target.value
        });
      }
    }
  };

  // submitLogin = (e) => {
  //   try {
  //     e.preventDefault();

  //     let { email, password1 } = this.state;

  //     if (email == "") {
  //       EventBus.publish("error", `Please enter email!`);
  //       return;
  //     }

  //     if (!email.replace(/\s/g, '').length) {
  //       EventBus.publish("error", `Please enter email!`);
  //       return;
  //     }

  //     email = email.toLowerCase();
  //     let isValis = validator.isEmail(email);
  //     if (!isValis) return EventBus.publish("error", "Email is invalid");

  //     if (password1 == "") {
  //       EventBus.publish("error", `Please enter password!`);
  //       return;
  //     }

  //     if (!password1.replace(/\s/g, '').length) {
  //       EventBus.publish("error", `Please enter password`);
  //       return;
  //     }

  //     this.props.loginLoader({ status: true, message: "Logging in..." });

  //     this.props.login({ email, password: password1 });

  //   } catch (error) {
  //     EventBus.publish("error", `Login Failed!`);
  //     console.log("error", error)
  //     return;
  //   }
  // }

  submitLogin = (e) => {
    try {
      e.preventDefault();

      let { publicAddress, kycStatus } = this.props;

      if (publicAddress == null || publicAddress == undefined) {
        EventBus.publish("error", `Please connect your wallet!`);
        return;
      }

      if (kycStatus == false) {
        EventBus.publish("error", `Please submit kyc!`);
        return;
      }

      if (kycStatus == "expired") {
        EventBus.publish("error", `Please submit kyc again!`);
        return;
      }

      if (kycStatus == "abandoned") {
        EventBus.publish("error", `Please submit kyc again!`);
        return;
      }

      if (kycStatus == "resubmission_requested") {
        EventBus.publish("error", `Please submit kyc again!`);
        return;
      }

      if (kycStatus == "review") {
        EventBus.publish("info", `Your KYC decision is still pending!`);
        return;
      }

      // if (kycStatus == "created") {
      //   EventBus.publish("info", `Your KYC decision is still pending!`);
      //   return;
      // }

      this.props.loginLoader({ status: true, message: "Logging in..." });

      this.props.login({ publicAddress, history:this.props.history});

    } catch (error) {
      EventBus.publish("error", `Login Failed!`);
      this.props.loginLoader({ status: false, message: "Logging in..." });
      console.log("error", error)
      return;
    }
  }

  render() {
    let { email, password1, type, sticky } = this.state;
    let { isLoader, auth, userData, publicAddress, kycStatus, kycValue } = this.props;
    console.log("*** publicAddress",publicAddress);
    console.log("*** kycStatus",kycStatus);
    console.log("*** kycValue",kycValue);

    // if (auth) {
    //   if(userData['quizStatus'] !== "pass"){
    //     return <Redirect to="select-category" />
    //   } else {
    //     return <Redirect to="course" />
    //   }
    // }

    return (
      <div className="wrapper sign-in" onWheel={this.onScroll}>

        <section class="form-main sign-up">
          <div class="wrap">
            <div className="inner">
              <h1>Sign in</h1>
              <form onSubmit={this.submitLogin}>

                {/* <div class="form-group">
                  <input
                    class="form-control"
                    type="text"
                    name="email"
                    defaultValue={email}
                    placeholder="Email"
                    onChange={this.handleChange}
                  />
                </div>

                <div class="form-group text-right">
                  <input
                    class="form-control password"
                    type={type}
                    name="password1"
                    defaultValue={password1}
                    placeholder="Password"
                    onChange={this.handleChange}
                  />
                  <span className="password__show" onClick={this.showHide}>{this.state.type === 'input' ? 'Hide' : 'Show'} Password</span>
                </div> */}

                  <input type="submit" value="Login with metamask" name="submit" />
              </form>
              <p>Dont have an account? <a href="/verify-kyc">Sign up</a></p>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

const mapDispatchToProps = { toggleLoader, login, loginLoader, getKycStatus, setAddress };

const mapStateToProps = ({ Auth }) => {
  let { publicAddress, kycStatus, userData, isLoader, kycValue, auth } = Auth;
  return { publicAddress, kycStatus, userData, isLoader, kycValue, auth };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SignIn));
