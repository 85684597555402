import EventBus from "eventing-bus";
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import React, { Component } from 'react';
import { HashLink } from 'react-router-hash-link';

import './index.css';
import { web3 } from '../../store/web3';
import { networkId, message } from '../../store/config';
import { logout, getResult, getKycStatus, setAddress } from '../../store/actions/Auth';

class Navbar extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    };

    async componentDidMount() {
        let deployer = (await web3.currentProvider.enable())[0];
        if (deployer == null || deployer == undefined) {
          EventBus.publish("error", `Please connect your wallet!`);
          return;
        }
        this.props.setAddress(deployer);
        this.props.getKycStatus({ publicAddress: deployer });
      }

    signout = () => {
        this.props.logout();
    }


    render() {
        let {  } = this.state;
        let {  } = this.props;

        return (
            <header>
                <div class="container">
                    <nav class="navbar navbar-expand-lg">
                        <a class="logo" href="/">
                            <img src="/images/logo.png" alt="" />
                        </a>

                        <div className="profile">
                            <a href="">
                                <span onClick={this.signout}>Logout</span>
                            </a>
                        </div>
                    </nav>
                </div>
            </header>
        );
    }
}

const mapDispatchToProps = {
    logout, getResult, getKycStatus, setAddress
};

const mapStateToProps = ({ Auth }) => {
    let { publicAddress, kycStatus, kycValue, userSoulBound } = Auth;
    return { publicAddress, kycStatus, kycValue, userSoulBound }
};

export default connect(mapStateToProps, mapDispatchToProps)(Navbar);