import EventBus from "eventing-bus";
import { connect } from "react-redux";
import Countdown from "react-countdown";
import OwlCarousel from "react-owl-carousel";
import React, { Component, Fragment } from "react";
import { validate } from "wallet-address-validator";
import { HashLink } from "react-router-hash-link";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";

import "./index.css";
import "../../static/css/animate.css";
import "owl.carousel/dist/assets/owl.carousel.css";

import { web3 } from "../../store/web3";
import Navbar from "../../components/navbar";
import Footer from "../../components/footer";
import NFT from "../../static/images/landing/NFT.gif"
import { networkId, message } from "../../store/config";
import { toggleLoader, sendResult } from "../../store/actions/Auth";
import { ICO, ICOAddress } from "../../store/contract/index";

class Congratulation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sticky: false,
      quizState: false,
      quantity: 0,
      value1: "",
      value2: "",
      value3: "",
      value4: "",
      value5: "",
      address: localStorage.getItem("publicAddress"),
    };
  }

  async componentDidMount() {
    let { publicAddress } = this.props;
    this.setState({ address: publicAddress });
  }

  componentWillReceiveProps({ publicAddress }) {
    this.setState({ address: publicAddress });
  }

  handleQuantity = () => {
    let { address } = this.state;
    if (address == "" || address == null) {
      EventBus.publish("error", `Connect to Metamask`);
      return;
    }
  };

  onScroll = () => {
    const { pageYOffset } = window;
    if (pageYOffset > 20) this.setState({ sticky: true });
    if (pageYOffset < 20) this.setState({ sticky: false });
  };

  handleChange = (e) => {
    if (e.target.name == "value1") {
      this.setState({ value1: e.target.value });
    } else if (e.target.name == "value2") {
      this.setState({ value2: e.target.value });
    } else if (e.target.name == "value3") {
      this.setState({ value3: e.target.value });
    } else if (e.target.name == "value4") {
      this.setState({ value4: e.target.value });
    } else if (e.target.name == "value5") {
      this.setState({ value5: e.target.value });
    }
  };

  submitAssessment = () => {
    let { value1, value2, value3, value4, value5 } = this.state;
    let { publicAddress } = this.props;
    if (publicAddress == null) {
      EventBus.publish("error", `Please connect your wallet!`);
      return;
    }
    let count = 0;
    if (value1 == "true") {
      count += 1;
    }
    if (value2 == "true") {
      count += 1;
    }
    if (value3 == "true") {
      count += 1;
    }
    if (value4 == "true") {
      count += 1;
    }
    if (value5 == "true") {
      count += 1;
    }
    if (count >= 4) {
      console.log("**** count", count);
      this.props.sendResult({ publicAddress, status: true });
      this.setState({ quizState: false });
    } else {
      this.props.sendResult({ publicAddress, status: false });
      this.setState({ quizState: false });
    }
  }

  quiz = () => {
    console.log("***********************");
    this.setState({ quizState: true });
  }

  render() {
    let { value1, value2, value3, value4, value5, sticky, quizState } = this.state;
    console.log("*** quizState", quizState);
    return (
      <div className="wrapper" onWheel={this.onScroll}>
        <Navbar sticky={sticky} />

        <div class="congratulation-msg">
          <div class="container">
            <div class="thumbnail">
              <div class="progress">
                <span>100%</span>
              </div>
            </div>
            <h1>Hooray!</h1>
            <p>You have completed the test</p>
            {/* <img
              src={NFT}
              alt="NFT"
              className="object-contain w-full h-full"
            /> */}
            <br />
            <br />
            <br />
            <a href="/course">
              <div class="arrow-wrap">
                <div class="arrow">
                  <span></span>
                  <span></span>
                  <span></span>
                </div>
              </div>

              Start uploading courses
            </a>
          </div>
        </div>

        <Footer />
      </div>
    );
  }
}

const mapDispatchToProps = { toggleLoader, sendResult };

const mapStateToProps = ({ Auth }) => {
  let { publicAddress } = Auth;
  return { publicAddress };
};

export default connect(mapStateToProps, mapDispatchToProps)(Congratulation);
