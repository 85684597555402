import EventBus from "eventing-bus";
import { connect } from "react-redux";
import Countdown from "react-countdown";
import OwlCarousel from "react-owl-carousel";
import React, { Component, Fragment } from "react";
import { validate } from "wallet-address-validator";
import { HashLink } from "react-router-hash-link";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
// import ApexCharts from 'apexcharts'
// import Chart from 'react-apexcharts'

import "./index.css";
import "../../static/css/animate.css";
import "owl.carousel/dist/assets/owl.carousel.css";

import { web3 } from "../../store/web3";
import Navbar from "../../components/navbar";
import Footer from "../../components/footer";
import { networkId, message } from "../../store/config";
import { toggleLoader, uploadCourse, getCourses, getResult } from "../../store/actions/Auth";
import { ICO, ICOAddress } from "../../store/contract/index";

class Statistics extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sticky: false,
      imageURL: '',
      image: '',
      contentURL: '',
      content: '',
      description: '',
      title: '',
      category: '',
      author: '',
      publicAddress: localStorage.getItem("publicAddress"),
      statusUser: localStorage.getItem("userStatus"),

      series: [{
        name: "Expense",
        data: [100, 300, 200, 50, 420, 320, 200, 150, 100, 250, 130, 400]
      }],

      options: {
        chart: {
          height: 350,
          type: 'line',
          zoom: {
            enabled: false
          }
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: 'straight',
          width: 2,
          colors: ['#38A9FF'],
        },

        title: {
          text: 'Expense graph',
          align: 'left'
        },
        grid: {
          row: {
            colors: ['transparent'], // takes an array which will be repeated on columns
            opacity: 1
          },
        },
        xaxis: {
          categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep','Oct', 'Nov', 'Dec'],
        }
      },
    };


  }

  async componentDidMount() {
    let {publicAddress}= this.state;
    this.props.getResult({ publicAddress });
    await this.props.getCourses();
  }

  
  componentWillReceiveProps({ userStatus, availableCourses }) {
    this.setState({ statusUser: userStatus });
  }

  onScroll = () => {
    const { pageYOffset } = window;
    if (pageYOffset > 20) this.setState({ sticky: true });
    if (pageYOffset < 20) this.setState({ sticky: false });
  };

  handleChange = (e) => {
    if (e.target.name == "title") {
      this.setState({ title: e.target.value });
    } else if (e.target.name == "description") {
      this.setState({ description: e.target.value });
    } else if (e.target.name == "author") {
      this.setState({ author: e.target.value });
    } else if (e.target.name == "category") {
      this.setState({ category: e.target.value });
    } else if (e.target.name == "imageURL") {
      this.setState({ imageURL: e.target.value });
    }
  };

  setImageURL = async (e) => {
    let test = e.target.files[0];
    console.log("*** test", test);
    this.setState({ imageURL: test });
    // let img = e.currentTarget.files[0]
    // if (img['size'] > 50000000) {
    //   EventBus.publish('info', 'Image should be smaller than 50 MB');
    //   return;
    // }
    // let image = URL.createObjectURL(img);
    // let res = await fetch(image);
    // let blob = await res.blob();
    // let imageURL = new File([blob], "File name", { type: "image/png" });
    // this.setState({ imageURL, image });
  };

  setContentURL = async (e) => {
    let test = e.target.files[0];
    console.log("*** test", test);
    this.setState({ contentURL: test });
    // let courseContent = e.currentTarget.files[0]
    // if (courseContent['size'] > 500000000) {
    //   EventBus.publish('info', 'File should be smaller than 500 MB');
    //   return;
    // }
    // let content = URL.createObjectURL(courseContent);
    // let res = await fetch(content);
    // let blob = await res.blob();
    // let contentURL = new File([blob], "File name");
    // this.setState({ contentURL, content });
  };

  clearImageURL = () => {
    let { image, imageURL } = this.state;
    image = '';
    imageURL = '';
    this.setState({ imageURL, image });
  };

  submitCourse = async () => {
    let { imageURL, contentURL, description, title, category, author, statusUser, publicAddress } = this.state;
    let { userStatus } = this.props;
    if (publicAddress == null) {
      EventBus.publish("error", `Please connect your wallet!`);
      return;
    }
    if (!userStatus) {
      EventBus.publish("error", `You need to pass the quiz first!`);
      return;
    }
    if (imageURL == '') {
      EventBus.publish("error", "Please upload banner for course");
      return;
    }
    if (contentURL == '') {
      EventBus.publish("error", "Please upload content for course");
      return;
    }
    if (description == '') {
      EventBus.publish("error", "Please add description for course");
      return;
    }
    if (title == '') {
      EventBus.publish("error", "Please add title for course");
      return;
    }
    if (category == '') {
      EventBus.publish("error", "Please add category for course");
      return;
    }
    if (author == '') {
      EventBus.publish("error", "Please add author for course");
      return;
    }

    let data = new FormData();
    data.append('data', contentURL);
    data.append('banner', imageURL);
    data.append('publicAddress', publicAddress);
    data.append('name', title);
    data.append('author', author);
    data.append('description', description);
    data.append('category', category);

    await this.props.uploadCourse(data);
  }

  render() {
    let { publicAddress, imageURL, contentURL, description, title, author, sticky, statusUser } = this.state;
    let { userStatus, availableCourses } = this.props;
    // console.log("*** description", description);
    // console.log("*** title", title);
    // console.log("*** author", author);
    // console.log("*** sticky", sticky);
    // console.log("*** category", category);
    // console.log("*** publicAddress", publicAddress);
    console.log("*** userStatus", userStatus);
    // console.log("*** statusUser", statusUser);
    // console.log("*** image", imageURL);
    // console.log("*** content", contentURL);
    return (
      <div className="wrapper" onWheel={this.onScroll}>
        <Navbar sticky={sticky} />

        <div className="statistics-chart-wrapper">
            <div className="container">
              {/* <div className="chart-wrap">
                  <Chart options={this.state.options} series={this.state.series} type="line" height={350}  />

                  <div className="course-chart-info">
                    <div className="info">
                        <p>Total Expense: $4599.89</p>
                    </div>

                    <div className="info">
                        <p>Course bought: 31</p>
                    </div>

                    <div className="info">
                        <p>Course completed: 29</p>
                    </div>
                  </div>
              </div> */}
            </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = { toggleLoader, uploadCourse, getCourses, getResult };

const mapStateToProps = ({ Auth }) => {
  let { publicAddress, userStatus, availableCourses } = Auth;
  return { publicAddress, userStatus, availableCourses };
};

export default connect(mapStateToProps, mapDispatchToProps)(Statistics);
