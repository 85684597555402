import EventBus from "eventing-bus";
import { connect } from "react-redux";
import Countdown from "react-countdown";
import OwlCarousel from "react-owl-carousel";
import React, { useEffect, useMemo, useState } from "react";
import ReactDOM from 'react-dom';
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';
import { Link } from 'react-router-dom';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import "./index.css";
import "../../static/css/animate.css";
import "owl.carousel/dist/assets/owl.carousel.css";
import { web3 } from "../../store/web3";
import Navbar from "../../components/navbar";
import Sidebar from "../../components/sidebar";
import { uploadToPinata } from './helper';
import { toggleLoader, uploadCourse, getCourses, getResult } from "../../store/actions/Auth";
import { Subscription, SubscriptionAddress, AdminSoulBoundABI, TokenABI, MarketpalceABI, Marketplace, MarketplaceAddress, CertificateNFTABI, CertificateNFTBytecode} from "../../store/contract/index";
const correct = "/images/correct-icon.png";
const loader = "/images/loader.png";
const uploadimg = "/images/camera.png";
const course = "/images/course1.png";
const xdc = "/images/xdc.png";

function Course(props) {
  let [addCourseModal, setAddCourseModal] = useState(false);
  let [paymentSuccessfulModal, setPaymentSuccessfulModal] = useState(false);
  let [courseDetailModal, setCourseDetailModal] = useState(false);
  let [editcourseDetailModal, setEditCourseDetailModal] = useState(false);
  let [mcqsSetupModal, setMcqsSetupModal] = useState(false);
  let [refreshPageModal, setRefreshPageModal] = useState(false);
  let [addSectionModal, setAddSectionModal] = useState(false);
  let [editSectionModal, setEditSectionModal] = useState(false);
  let [courseImage, setCourseImage] = useState("");
  let [courses, setCourses] = useState([]);

  let [video, setVideo] = useState();
  let [videoPreview, setVideoPreview] = useState([]);
  let [uploadLivePeerVideos, setUploadLivePeerVideos] = useState([]);
  let [uploadedIndex, setUploadedIndex] = useState("");

  let [tokenType, setTokenType] = useState("COIN");
  let [chainId, setChainId] = useState("XDC")
  let [tokenAddress, setTokenAddress] = useState("");
  let [tokenSymbol, setTokenSymbol] = useState("");
  let [totalLectures, setTotalLectures] = useState(1);
  let [loadSubscription, setLoadSubscription] = useState(false);
// sections
  let [section, setSection] = useState([{section:"Section 1",lectures:[],isUploaded:false}]);
  let [activeTab, setActiveTab] = useState(1);
  let [sectionTitle, setSectionTitle] = useState("");
  let [editSection, setEditSection] = useState("");
  let [updateSectionTitle, setUpdateSectionTitle] = useState("");
  let [sectionIndex, setSectionIndex] = useState(0);
  // let [sectionUploadedCompleted, setSectionUploadedCompleted] = useState(0);
  let [videoIndex, setVideoIndex] = useState(0);

  let [state, setState] = useState({
    sticky: false,
    description: '',
    title: '',
    category: '',
    price: 0,
    level:'',
    coursePrice: '',
    subscription: false,
    publicAddress: localStorage.getItem("publicAddress"),
    statusUser: localStorage.getItem("userStatus"),
  });

  const editSectionTitle = (item) => {
    setEditSection(item)
    setEditSectionModal(true);
  }
  
  const sectionTitleUpdated = (idx) => {
    if(updateSectionTitle){
      section[idx].section = updateSectionTitle;
      setSection(section)
    }
    setEditSectionModal(false);
  }

  const deleteSection = (idx) => {
    section.splice(idx, 1)
    if(section.length > 1) setActiveTab(idx)
    else if(section.length == 1) setActiveTab(1)
    setSection(section);
    setEditSectionModal(false);
   }

  const addsection = () => {
    if(!!updateSectionTitle.trim()) return EventBus.publish("error", `Please set section title!`);
    setSection([...section,{section:sectionTitle,lectures:[],isUploaded:false}])
    setActiveTab(section.length+1)
    setAddSectionModal(false);
  }

  const handleTabClick = (tabNum) => {
    setActiveTab(tabNum);
  };

  async function getContract() {
    let deployer = (await web3.currentProvider.enable())[0];
    let price = await Subscription.methods.courseUploadAmount().call();
    let sub = await Subscription.methods.subscribeCourseUpload(deployer).call();
    if(sub){
      price = web3.utils.fromWei(price.toString(), "ether");
      state['subscription'] = sub['status']
      state['price'] = parseFloat(price)
      state['loadSubscription'] = true;
      state['publicAddress'] = deployer
      setState(state);
      setLoadSubscription(true)
      props.getCourses();
    }
  }

  useEffect(() => {
    props.toggleLoader({message:"Get courses...", status:true});
    getContract();
  }, [])
  
  useEffect(() => {
    if(props.categories.length > 0){
      state['category'] = props.categories[0]['_id'];
      setState(state);
    }
  }, [props.categories])

  useEffect(()=>{
    if(props.availableCourses && props.availableCourses.length > 0){
      setCourses(props.availableCourses);
      setCourseDetailModal(false);
      setVideo()
      setSection([{section:"Section 1",lectures:[],isUploaded:false}])
      setVideoPreview([])
      setUploadLivePeerVideos([])
      setUploadedIndex("")
      setCourseImage("")
      setTokenAddress("")
      setTokenType("COIN")
      setChainId("XDC");
      setTotalLectures(1)
      state['description'] = ""
      state['title'] = ""
      state['category'] = props.categories.length > 0 ? props.categories[0]['_id'] : ""
      state['level'] = ""
      state['price'] = 0
      setState(state);
    }
  },[props.availableCourses]);

  useEffect(() => {
    state['userStatus'] = props.userStatus;
    setState(state);
  }, [props.userStatus, props.availableCourses]);


  const uplodCourse = async (e) => {
    try {
      e.preventDefault()
      let { description, title, coursePrice, category, publicAddress, level } = state;
      if (publicAddress == null || publicAddress == undefined) {
        EventBus.publish("error", `Please connect your wallet!`);
        return;
      }
      let deployer = (await web3.currentProvider.enable())[0];
      let balanceWei = await web3.eth.getBalance(deployer);
      let balanceEther = web3.utils.fromWei(balanceWei, 'ether');
      if (balanceEther == 0) return EventBus.publish("error", `Insufficient balance for transaction`);
  
      if(!props.userSoulBound) return EventBus.publish("error", `Issues in soul bound token `);
  
      if(!description) return EventBus.publish("error", "Please enter course description");
      if(!title) return EventBus.publish("error", "Please enter course title");
      if(!level) return EventBus.publish("error", "Please enter course level");
      if(!category) return EventBus.publish("error", "Please enter course category");
      if(!coursePrice) return EventBus.publish("error", "Please enter course price");
      if(coursePrice == 0) return EventBus.publish("error", "Price must be grather then 0");
      if(tokenType == "TOKEN") {
        let ethAddressRegex = /^(0x)?[0-9a-fA-F]{40}$/;
        // Check if the address matches the expected pattern
        let isValidAddress = ethAddressRegex.test(tokenAddress);
        if(!isValidAddress) return EventBus.publish('error', "Invalid Token Address");
        let Token = new web3.eth.Contract(TokenABI, tokenAddress);
        let symbol = await Token.methods.symbol().call();
        setTokenSymbol(symbol);
        if(!symbol) return EventBus.publish("error", "Invalid token address");
      }
      for (let index = 0; index < section.length; index++) {
        const element = section[index];
        if(element['lectures'].length == 0) return EventBus.publish("error",`Please upload lecture videos of ${element.section}`);
      }
      setActiveTab(1);
      
      for (let index = 0; index < section.length; index++) {
        let element = section[index];
        let newArrays = [];
        props.toggleLoader({message:`Uploading ${element.section}...`, status: true});
        for (let j = 0; j < element.lectures.length; j++) {
          let electures = element.lectures[j];
          let ipfsHash = await uploadToPinata(electures);
          newArrays.push({videoTitle:electures['videoTitle'],playbackUrl:ipfsHash})
        }
        element['lectures'] = newArrays;
      }
      
      submitCourse();
    } catch (error) {
      props.toggleLoader({message:`Uploading ...`, status: false});
      return  EventBus.publish("error", error.message);
    }
  }

  function generateCustomNonce() {
    // Assuming you have a unique identifier or timestamp
    const uniqueValue = Date.now().toString(); // You can replace this with your own unique identifier logic
  
    // Combine the unique value with a random number to ensure uniqueness
    const randomNonce = Math.floor(Math.random() * 1000); // Adjust the range based on your needs
  
    // Concatenate the unique value and the random nonce
    const customNonce = uniqueValue + randomNonce;
  
    return customNonce;
  }

  const submitCourse = async () => {
    try {
      let { description, title, coursePrice, category, level } = state;
      
      let deployer = (await web3.currentProvider.enable())[0];

      let amountInWei = web3.utils.toWei(coursePrice.toString(), 'ether');

      props.toggleLoader({message:"Deploying course certificate...", status:true});

      let contract = new web3.eth.Contract(CertificateNFTABI);
      let deploy = contract.deploy({
        data: CertificateNFTBytecode,arguments: [amountInWei,title]
      });

      await deploy.send({ from: deployer })
        .on('transactionHash', hash => console.log(`************** deploy contract hash = ${hash}`))
        .on('receipt', async receipt => { 
      
      if(receipt['contractAddress']){
        props.toggleLoader({message:"Submitting in soul bound...", status:true});
        const NFTContractAddress = receipt['contractAddress'].toLowerCase();
        const nonce = generateCustomNonce();
        await web3.eth
        .sendTransaction({
          from: deployer,
          to: MarketplaceAddress,
          gas: 5000000,
          data: tokenType == "TOKEN" 
            ? 
            Marketplace.methods
            .addERC20Token(tokenAddress,amountInWei,nonce,props.userSoulBound,category,title)
            .encodeABI()
            : 
            Marketplace.methods
            .addXDCCoin(amountInWei,nonce,props.userSoulBound,category,title)
            .encodeABI(),
        })
        .on('transactionHash', hash => console.log(`************** tx hash = ${hash}`))
        .on('receipt', async receipt => {
          props.toggleLoader({
            message: "Finalizing course...",
            status: true,
          });
          let formDataToSend = new FormData();
          formDataToSend.append('image', courseImage);
          formDataToSend.append('description', description);
          formDataToSend.append('title', title);
          formDataToSend.append('price', coursePrice);
          formDataToSend.append('category', category);
          formDataToSend.append('type', tokenType);
          formDataToSend.append('tokenSymbol', tokenSymbol);
          formDataToSend.append('chainId', chainId);
          formDataToSend.append('tokenAddress', tokenAddress);
          formDataToSend.append('level', level);
          formDataToSend.append('nonce', nonce);
          formDataToSend.append('courseContractAddress', NFTContractAddress);
          formDataToSend.append('quizs',JSON.stringify(section));
          props.uploadCourse(formDataToSend);
        });
      }else{
        props.toggleLoader({
          message: "Uploading Failed...",
          status: false,
        });
        EventBus.publish("error", "network error");
        return;
      }
     
    });
    } catch (error) {
      console.log("error: ", error);
      props.toggleLoader({
        message: "Uploading Failed...",
        status: false,
      });
      EventBus.publish("error", error.message);
      return;
    }
   
  }

  const setContentURL = async (e,idx) => {
    let filterValues = section[idx].lectures.filter(items=>Array.from(e.target.files).find(item=>items.name === item.name));
    if(filterValues.length > 0) return;
    if(section[idx].lectures.length == 0){
      let selectedVideos = Array.from(e.target.files);
      selectedVideos = selectedVideos.map((item,index) => 
        {
        if(!item['isUploaded']){
          item['isUploaded'] = false;
          item['videoTitle'] = `Lecture ${index+1}`;
          return item
        }
        return item
        });
      setVideo(selectedVideos[0])
      setUploadLivePeerVideos([selectedVideos[0]])
      setVideoPreview(selectedVideos);
      section[idx].lectures = selectedVideos;
      setSection(section);
      setState({ ...state});
    }else{
      let selectedVideos = Array.from(e.target.files);
      selectedVideos = [...section[idx].lectures,...selectedVideos];
      selectedVideos = selectedVideos.map((item,index) => 
        {
        if(!item['isUploaded']){
          item['isUploaded'] = false;
          item['videoTitle'] = `Lecture ${index+1}`;
          return item
        }
        return item
        });
      // setVideo(selectedVideos[0])
      // setUploadLivePeerVideos([selectedVideos[0]])
      // setVideoPreview(selectedVideos);
      section[idx].lectures = selectedVideos;
      setSection(section);
      setState({ ...state});
    }
  
  };

  const setVideoTitle = async (e,index,idx) => {
    if(e.target.name){
      section[idx]['lectures'][index]["videoTitle"] = e.target.value;
      setSection(section);
    }
  }

  const deleteVideo = async (removeIndex,idx) => {
    let updatedLectures = section[idx]['lectures'].filter((_, index) => index !== removeIndex);
    let updatedSection = [...section];
    updatedSection[idx]['lectures'] = updatedLectures;
    setSection(updatedSection);
  }

  const handleSubscription = async (e) => {
    try {

      let { publicAddress, price } = state;
      if (publicAddress == null || publicAddress == undefined) {
        EventBus.publish("error", `Please connect your wallet!`);
        return;
      }
      if (price == 0) {
        EventBus.publish("error", `Subscription amount cannot be zero!`);
        return;
      }
      let deployer = (await web3.currentProvider.enable())[0];

      const balanceWei = await web3.eth.getBalance(deployer);
      const balanceEther = web3.utils.fromWei(balanceWei, 'ether');
      if (balanceEther == 0) return EventBus.publish("error", `Insufficient balance for transaction`);

      let sub = await Subscription.methods.subscribeCourseUpload(deployer).call();

      if (!sub['status']) {
        props.toggleLoader({
          message: "Subscription in Progress...",
          status: true,
        });
        let req = web3.utils.toWei(price.toString(), "ether");
        if (parseInt(balanceWei) < parseInt(req)) {
          props.toggleLoader({
            message: "Subscription Failed...",
            status: false,
          });
          EventBus.publish("error", `Don't have enough XDC to subscribe!`);
          return;
        } else {

          await web3.eth
            .sendTransaction({
              from: publicAddress,
              value: req,
              to: SubscriptionAddress,
              gas: 5000000,
              data: Subscription.methods
                .courseUploadSubscription()
                .encodeABI(),
            })
            .on('transactionHash', hash => console.log(`************** tx hash = ${hash}`))
            .on('receipt', async receipt => {
              getContract();
              props.toggleLoader({
                message: "Subscription successfully completed...",
                status: false,
              });
              EventBus.publish("success", `Subscription successfully completed`);
              setAddCourseModal(false);
              setPaymentSuccessfulModal(true);
            });
        }
      }
    } catch (e) {
      console.log("error: ", e);
      props.toggleLoader({
        message: "Subscription Failed...",
        status: false,
      });
      setAddCourseModal(false);
      EventBus.publish("error", e.message);
      return;
    }
  }

  const handelTokens = (value) => {
    setTokenType(value);
  }

  const checkToken = async(tokenAddress)=>{
    setTokenAddress(tokenAddress)
    let ethAddressRegex = /^(0x)?[0-9a-fA-F]{40}$/;
    // Check if the address matches the expected pattern
    let isValidAddress = ethAddressRegex.test(tokenAddress);
    // if(!isValidAddress) return EventBus.publish('error', "Invalid Token Address");
    if(isValidAddress){
      let Token = new web3.eth.Contract(TokenABI, tokenAddress);
      let symbol = await Token.methods.symbol().call();
      setTokenSymbol(symbol);
      if(!symbol) return EventBus.publish("error", "Invalid token address");
    }
  }

  let courseslider = {
    slidesToShow: 2,
    slidesToScroll: 1,
    dots: false,
    arrows: true,
    speed: 1000,
    infinite: false,
    autoplay: false,
    responsive: [
        {
          breakpoint: 992,
          settings: {
            slidesToShow: 2
          }
        },
        {
          breakpoint: 576,
          settings: {
            slidesToShow: 1
          }
        }
    ]
  };

  let { description, title, coursePrice, price, category, subscription, level } = state;
  let { categories, availableCourses } = props;
  return (
    <div className="wrapper">
      <Navbar />

      <div className="admin-wrapper">
        <Sidebar />
        {loadSubscription &&
        <div className="inner">
          <div className="courses">
            <div class="profiles">
              {
                !subscription ?
                  <button href="" onClick={() => setAddCourseModal(true)} class="course video-uploader">
                    <input type="file" class="fileuploader" style={{ display: "none" }} />
                    <img class="thumbnail" src="/images/add-course-icon.png" alt="video-add" />
                    <button class="fileuploader-btn">Add new course</button>
                  </button> :
                  <button href="" onClick={() => setCourseDetailModal(true)} class="course video-uploader">
                    <input type="file" class="fileuploader" style={{ display: "none" }} />
                    <img class="thumbnail" src="/images/add-course-icon.png" alt="video-add" />
                    <button class="fileuploader-btn">Add new course</button>
                  </button>
              }
              
              
              {props.availableCourses && props.availableCourses.length > 0 &&
              props.availableCourses.map(item=>
              <div class="course">
                <img src={item['courseImage']} alt="" />

                <div className="btn-wrap">
                  <Link to={`/add-quiz/${item['_id']}`} className="common-btn add-quiz-btn">Add Quiz</Link>
                </div>

                <div class="course-content">
                  <div class="head">
                    <h3>{item['title']}</h3>
                    <p>{item['category']['categoryName']}</p>
                  </div>

                  <div class="bottom-info">
                    <div className="price">{item['price']} { item['type'] == "TOKEN" ? item['tokenSymbol'] : "XDC"}</div>
                  </div>
                </div>
              </div>
              )
              }
               {/* <div class="course">
                <img src={course} alt="" />

                <div className="btn-wrap">
                  <Link to="/add-quiz" className="common-btn add-quiz-btn">Add Quiz</Link>

                  <button onClick={() => setEditCourseDetailModal(true)} className="common-btn">Edit</button>
                </div>

                <div class="course-content">
                  <div class="head">
                    <h3>Darrell Steward</h3>

                    <p>Art & Design</p>
                  </div>

                  <div class="bottom-info">
                    <div>
                      <span>2h 23m</span>

                      <span>147k views</span>
                    </div>

                    <div className="price">dss XDC</div>
                  </div>
                </div>
              </div>  */}
            </div>
          </div>
        </div>
        }
      </div>
      {/* Pay token models  */}
      <Modal
        open={addCourseModal}
        onClose={() => setAddCourseModal(false)}
        classNames={{
          modal: `common-modal add-course-modal`
        }}
        center
      >
        <div class="modal-content">
          <div class="modal-header">
            <button type="button" className="close" onClick={() => setAddCourseModal(false)}>
              <span aria-hidden="true">&times;</span>
            </button>
          </div>

          <div class="modal-body">
            <div class="form-main">
              <div class="inner">
                <h1>Pay utility token</h1>

                <form >
                  <div className="form-group payment">
                    <label for="fee">Fee</label>

                    <input id="fee" type="text" class="form-control" value={`${price} XDC`} readOnly />
                  </div>

                  <p>You will be charged {price} XDC tokens to upload your course on Learn to earn marketplace.</p>

                  <div class="form-group mb-3">
                    <button type="button" className="common-btn" onClick={() => handleSubscription()}>
                      Pay now
                    </button>
                  </div>

                  <div class="form-group text-center mb-0">
                    <a className="go-back" href="" aria-label="Close" data-dismiss="modal">
                      Go back
                    </a>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </Modal>

      <Modal
        open={paymentSuccessfulModal}
        onClose={() => setPaymentSuccessfulModal(false)}
        classNames={{
          modal: `common-modal payment-successful-modal`
        }}
        center
      >
        <div class="modal-content">
          <div class="modal-header">
            <button type="button" className="close" onClick={() => setPaymentSuccessfulModal(false)}>
              <span aria-hidden="true">&times;</span>
            </button>
          </div>

          <div class="modal-body">
            <div class="form-main">
              <div class="inner">
                <h1 className="mb-4">Payment successful!</h1>

                <form >
                  <p>Cheers! Now you can upload your course.</p>

                  <div class="form-group mb-0">
                    <button type="button" className="common-btn" onClick={() => { setPaymentSuccessfulModal(false); setCourseDetailModal(true) }}>
                      Proceed to course upload
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </Modal>

      {/* course model */}
      <Modal
        open={courseDetailModal}
        onClose={() => setCourseDetailModal(false)}
        classNames={{
          modal: `common-modal course-detail-modal`
        }}
        center
      >
        <div class="modal-content">
          <div class="modal-header">
            <button type="button" className="close" onClick={() => setCourseDetailModal(false)}>
              <span aria-hidden="true">&times;</span>
            </button>
          </div>

          <div class="modal-body">
            <div className="add-file-quiz-wrap">
              <div className="top-head">
                <ul class="nav nav-tabs" id="myTab" role="tablist">
                  <Slider class="nav nav-tabs" id="myTab" role="tablist" {...courseslider}>
                    {section && section.length > 0 &&
                      section.map((item,idx)=>
                      <li class="nav-item">
                        <a className={`nav-link ${activeTab === idx+1 ? 'active' : ''}`} onClick={() => handleTabClick(idx+1)} id={`tab-${idx+1}`} data-toggle="tab" href={`#tab${idx+1}`} role="tab" aria-controls={`tab1${idx+1}`} aria-selected="true">{item.section} <img  onClick={() => editSectionTitle(idx)} src="/images/edit-title.png" alt="edit"/></a>
                      </li>
                    )}
                  </Slider>
                </ul>
                <div onClick={() => setAddSectionModal(true)} className="add-new-handler">
                  Add new
                  <img src="/images/add-square.png" alt="add"/>
                </div>
              </div>
              <div class="tab-content" id="myTabContent">
              {section && section.length > 0 &&
              section.map((item,idx)=>
                <div className={`tab-pane fade ${activeTab === idx+1 ? 'show active' : ''}`} id={`tab${idx+1}`} role="tabpanel" aria-labelledby={`tab-${idx+1}`}>
                <div className="add-quiz-wrap">
                {item.lectures && item.lectures.length > 0 ?
                  item.lectures.map((video, index) => (
                    <div className="quiz-row">
                      {video &&
                      <video className="video-wrap" width="320" height="240">
                        <source class="play" src={video['playbackUrl'] ? video['playbackUrl'] : URL.createObjectURL(video)} type="video/mp4" />
                      </video>
                      }
                      {item.isUploaded == false ?
                        uploadedIndex == video.name ?
                          <div className="status">Section {sectionIndex + 1}</div>
                          // <div className="status">Section {sectionIndex + 1} {progressFormatted}</div>
                          // <img className="video-loader" src={loader} />
                          :
                          <div className="video-content-wrap">
                              {/* <p>{video.name.slice(0,25)+"..."}</p> */}
                              <input type="text" class="form-control" placeholder="Title" defaultValue={video.videoTitle.length > 25 ? video.videoTitle.slice(0,25)+"..." : video.videoTitle} onChange={e=>setVideoTitle(e,index,idx)}/>
                              {/* <button type="button" className="common-btn default-btn" onClick={() => {setMcqsSetupModal(true); setCourseDetailModal(false) }}>Add Quiz</button> */}
                              <img class="delete" src="/images/trash.png" alt="delete" onClick={()=>deleteVideo(index,idx)} />
                          </div>
                      :
                        <div className="status">{video.name}</div>
                      }
                  </div>
                  ))
                  :
                  <div className="video-not-uploaded">
                    <p>Videos not uploaded of {item.section}</p>
                  </div>
                }
                
              </div>
              {/* {!progressFormatted ? */}
              <div class="file-upload-wrap">
                <h3>Choose files or drag and drop here</h3>

                <span>Files supported: MP4</span>

                <button className="common-btn">Select files</button>

                <p>Maximum file size for each video: 2 GB</p>

                <input type="file" multiple accept="video/*" onChange={e => setContentURL(e,idx)} />
              </div>
              {/* :
              <div class="file-upload-wrap">
                <h3> {progressFormatted}</h3>
              </div> */}
              {/* } */}
                </div>
              )}
              </div>
            </div>

            <div class="form-main">
              <div class="inner">
                <h1 className="">Course details</h1>

                {/* <form onSubmit={submitCourse}> */}
                <form onSubmit={uplodCourse}>
                  <div className="row">
                    <div class="form-group col-12">
                      <input type="text" class="form-control" placeholder="Add course title" defaultValue={title} onChange={e=>{
                        state['title'] = e.target.value;
                        setState(state);
                      }} />
                    </div>

                    <div class="form-group col-12">
                      <input type="text" class="form-control" placeholder="Add course description" defaultValue={description} onChange={e=>{
                        state['description'] = e.target.value;
                        setState(state);
                      }}/>
                    </div>

                    <div class="form-group col-12">
                      <select class="form-control" defaultValue={level} onChange={e=>{
                        state['level'] = e.target.value;
                        setState(state);
                      }}>
                        <option value="" selected disabled>Select level</option>
                        <option value="Beginner">Beginner</option>
                        <option value="Intermediate">Intermediate</option>
                        <option value="Expert">Expert</option>
                      </select>
                    </div>

                    <div class="form-group col-12">
                      <select class="form-control" defaultValue={category} onChange={e=>{
                        state['category'] = e.target.value;
                        setState(state);
                      }}>
                      {categories && categories.length>0 && 
                      categories.map(items=>
                        <option value={items['_id']}>{items['categoryName']}</option>
                      )
                      }
                        {/* <option value="" selected disabled>Select category</option>
                        <option value="Javascript">Javascript</option>
                        <option value="Blockchain">Blockchain</option>
                        <option value="Information-Technology">Information Technology</option> */}
                      </select>
                    </div>

                    <div class="form-group col-12 radio-wrap d-flex ml-3">
                      <div className="mx-2">
                        <input type="radio" name="COIN" id="xdc" value="COIN" checked={tokenType=="COIN"} onChange={e=> handelTokens(e.target.value) }/>
                        <label htmlFor="xdc">XDC</label>
                      </div>
                      <div className="mx-2">
                        <input type="radio" name="TOKEN" id="token" value="TOKEN" checked={tokenType=="TOKEN"} onChange={e=> handelTokens(e.target.value) }/>
                        <label htmlFor="token">Token</label>
                      </div>
                    </div>
                    {tokenType == "COIN" ?
                    <div class="form-group col-12 price-wrap">
                      <input type="number" class="form-control" placeholder="Set course price in..." defaultValue={coursePrice} onChange={e=>{
                        state['coursePrice'] = e.target.value;
                        setState(state);
                      }}/>

                      <span>XDC</span>
                    </div>
                    :
                    <>
                      <div class="form-group col-12 price-wrap">
                        <input type="number" class="form-control" placeholder="Set course price in..." defaultValue={coursePrice} onChange={e=>{
                          state['coursePrice'] = e.target.value;
                          setState(state);
                        }}/>

                        <span>{tokenSymbol ? tokenSymbol : ""}</span>
                      </div>
                      <div class="form-group col-12 price-wrap">
                       <input type="text" class="form-control" placeholder="Set token addressin..." value={tokenAddress ? tokenAddress : ""} onChange={e=>checkToken(e.target.value)}/>
                       {/* <span>{tokenSymbol ? tokenSymbol : ""}</span> */}
                      </div>
                    </>
                    }

                    <div class="form-group col-12">
                        <div className="file-wrap">
                            <input type="file" accept="image/*" class="form-control" placeholder="Select Image" onChange={e=> setCourseImage(e.target.files.length == 0 ? "" : e.target.files[0])}/>
                            <span>{courseImage ? courseImage.name.slice(0,25)+"..." : "Select Image"}</span>
                            <img src={uploadimg} />
                        </div>
                    </div>

                    <div class="form-group col-12 terms_conditions">
                      <input type="checkbox" id="terms_conditions" name="terms_conditions" />
                      <label for="terms_conditions"><span>Terms</span> and conditions <span>agreement</span></label>
                    </div>

                    {/* <div class="form-group col-md-6">
                      <button className="default-btn common-btn">Save draft</button>
                    </div> */}

                    <div class="form-group col-md-6">
                      {/* {!progressFormatted ? */}
                      <button className="common-btn" onClick={uplodCourse}>Publish course</button>
                      {/* :
                      <button className="common-btn" disabled>{progressFormatted}</button>
                      } */}
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </Modal>

      <Modal
        open={editcourseDetailModal}
        onClose={() => setEditCourseDetailModal(false)}
        classNames={{
          modal: `common-modal course-detail-modal`
        }}
        center
      >
        <div class="modal-content">
          <div class="modal-header">
            <button type="button" className="close" onClick={() => setEditCourseDetailModal(false)}>
              <span aria-hidden="true">&times;</span>
            </button>
          </div>

          <div class="modal-body">
            <div className="add-file-quiz-wrap">
              <div className="add-quiz-wrap">
                {videoPreview && videoPreview.length > 0 ?
                  videoPreview.map((video, index) => (
                    <div className="quiz-row">
                      {video &&
                      <video className="video-wrap" width="320" height="240">
                        <source class="play" src={video['playbackUrl'] ? video['playbackUrl'] : URL.createObjectURL(video)} type="video/mp4" />
                      </video>
                      }
                      {video.isUploaded == false ?
                        uploadedIndex == video.name ?
                          <div className="status"> {sectionIndex}</div>
                          // <div className="status">{progressFormatted} of Section {sectionIndex}</div>
                          // <img className="video-loader" src={loader} />
                          :
                          <div className="video-content-wrap">
                              <input type="text" class="form-control" placeholder="Title" defaultValue={video.name}/>
                              {/* <p>{video.name}</p> */}
                              {/* <button type="button" className="common-btn default-btn" onClick={() => {setMcqsSetupModal(true); setCourseDetailModal(false) }}>Add Quiz</button> */}
                              <img class="delete" src="/images/trash.png" alt="delete" onClick={()=>deleteVideo(index)} />
                          </div>
                      :
                        <div className="status">{video.name} Video Uploaded</div>
                      }
                  </div>
                  ))
                  :
                  <div className="video-not-uploaded">
                    <p>Videos not uploaded</p>
                  </div>
                }
              </div>
              {/* {!progressFormatted ? */}
              <div class="file-upload-wrap">
                <h3>Choose files or drag and drop here</h3>

                <span>Files supported: MP4, MOV, AVI, WEBM</span>

                <button className="common-btn">Select files</button>

                <p>Maximum file size for each video: 2 GB</p>

                <input type="file" multiple accept="video/*" onChange={e => setContentURL(e)} />
              </div>
              {/* :
              <div class="file-upload-wrap">
                <h3> {progressFormatted } { uploadedIndex} </h3>
              </div> */}
              {/* } */}
            </div>

            <div class="form-main">
              <div class="inner">
                <h1 className="">Course details</h1>

                <form onSubmit={uplodCourse}>
                  <div className="row">
                    <div class="form-group col-12">
                      <input type="text" class="form-control" placeholder="Add course title" defaultValue={title} onChange={e=>{
                        state['title'] = e.target.value;
                        setState(state);
                      }} />
                    </div>

                    <div class="form-group col-12">
                      <input type="text" class="form-control" placeholder="Add course description" defaultValue={description} onChange={e=>{
                        state['description'] = e.target.value;
                        setState(state);
                      }}/>
                    </div>

                    <div class="form-group col-12">
                      <select class="form-control" defaultValue={category} onChange={e=>{
                        state['category'] = e.target.value;
                        setState(state);
                      }}>
                        <option value="" selected disabled>Select category</option>
                        <option value="Beginner">Beginner</option>
                        <option value="Intermediate">Intermediate</option>
                        <option value="Expert">Expert</option>
                      </select>
                    </div>
                    <div class="form-group col-12 radio-wrap d-flex ml-3">
                      <div className="mx-2">
                        <input type="radio" id="xdc" name="radio-group" checked />
                        <label for="xdc">XDC</label>
                      </div>
                      <div className="mx-2">
                        <input type="radio" id="token" name="radio-group" />
                        <label for="token">Token</label>
                      </div>
                    </div>
                    <div class="form-group col-12  price-wrap">
                      <input type="number" class="form-control" placeholder="Set course price" defaultValue={coursePrice} onChange={e=>{
                        state['coursePrice'] = e.target.value;
                        setState(state);
                      }}/>
                      <span>XDC</span>
                    </div>

                    <div class="form-group col-12">
                        <div className="file-wrap">
                            <input type="file" accept="image/*" class="form-control" placeholder="Select Image" onChange={e=> setCourseImage(e.target.files[0])}/>
                            <span>Select Image</span>
                            <img src={uploadimg} />
                        </div>
                      </div>

                    <div class="form-group col-12 terms_conditions">
                      <input type="checkbox" id="terms_conditions" name="terms_conditions" />
                      <label for="terms_conditions"><span>Terms</span> and conditions <span>agreement</span></label>
                    </div>

                    {/* <div class="form-group col-md-6">
                      <button className="default-btn common-btn">Save draft</button>
                    </div> */}

                    <div class="form-group col-md-6">
                      {/* {!progressFormatted ? */}
                      <button className="common-btn" onClick={uplodCourse}>Publish course</button>
                      {/* :
                      <button className="common-btn" disabled>{progressFormatted}</button>
                      } */}
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </Modal>

      <Modal
        open={mcqsSetupModal}
        onClose={() => setMcqsSetupModal(false)}
        classNames={{
          modal: `common-modal mcqs-setup`,
        }}
        center
      >
        <div class="modal-header">
          <button type="button" class="close" onClick={() => setMcqsSetupModal(false)}>
            <span aria-hidden="true">&times;</span>
          </button>
        </div>

        <div class="modal-body">
          <div class="form-main">
            <div class="inner">
              <div className="head">
                <button className="default-btn common-btn" type="button" onClick={() => { setMcqsSetupModal(false); setCourseDetailModal(true) }}>Back</button>

                <div className="title-wrap">
                  <h1>Add MCQs</h1>

                  <p>Select correct option before adding question to the quiz.</p>
                </div>

                <button className="common-btn">Save</button>
              </div>
              <div className="row-wrap">
                <div className="row" >
                  {/* <div class="form-group col-12">
                                                    <h5 style={{color:"white"}}>Question No</h5>
                                                </div> */}
                  <div class="form-group col-12">
                    <textarea className="form-control" name="question" placeholder="Write question here"></textarea>
                  </div>

                  <div class="form-group col-md-6">
                    <label for="A">A</label>
                    <input id="A" type="text" class="form-control" placeholder="Write option A here" name="answerA" />
                  </div>

                  <div class="form-group col-md-6">
                    <label for="B">B</label>
                    <input id="B" type="text" class="form-control" placeholder="Write option B here" name="answerB" />
                  </div>

                  <div class="form-group col-md-6">
                    <label for="C">C</label>
                    <input id="C" type="text" class="form-control" placeholder="Write option C here" name="answerC" />
                  </div>

                  <div class="form-group col-md-6">
                    <label for="D">D</label>
                    <input id="D" type="text" class="form-control" placeholder="Write option D here" name="answerD" />
                  </div>

                  <div class="form-group col-md-6">
                    <label for="E"><img className="blue" src={correct} /></label>
                    <select id="E" class="form-control" name="correctAnswer">
                      <option value="" selected disabled>Select correct option</option>
                      <option>correct Answer</option>
                      <option>correct Answer</option>
                      <option>correct Answer</option>
                      <option>correct Answer</option>
                    </select>
                  </div>
                  <div class="form-group col-md-6 d-flex justify-content-end align-items-center">
                    <button className="delete-btn common-btn">Delete question</button>
                  </div>
                </div>
              </div>

              <div className="bottom justify-content-center">
              <div className="question-slides">
                                                <div className="prev">
                                                    <img src="/images/arrow-circle-left.png" alt="" />
                                                </div>
                                                <span>1/1</span>
                                                <div className="next">
                                                    <img src="/images/arrow-circle-right.png" alt="" />
                                                </div>
                                            </div>
                                            
                <button className="common-btn">Add question</button>
              </div>
            </div>
          </div>
        </div>
      </Modal>

      <Modal
        open={refreshPageModal}
        onClose={() => setRefreshPageModal(false)}
        classNames={{
          modal: `common-modal refresh-page`,
        }}
        center
      >
        <div class="modal-header">
          <button type="button" class="close" onClick={() => setRefreshPageModal(false)}>
            <span aria-hidden="true">&times;</span>
          </button>
        </div>

        <div class="modal-body">
          <h2>Are you sure you want to refresh the page?</h2>

          <div className="btn-wrap d-flex justify-content-between">
            <a href="#" className="common-btn"> 
                Yes
            </a>

            <a href="#" className="common-btn"> 
                No
            </a>
          </div>
        </div>
      </Modal>

      <Modal
        open={addSectionModal}
        onClose={() => setAddSectionModal(false)}
        classNames={{
          modal: `common-modal add-edit-section-modal`,
        }}
        center
      >
        <div class="modal-header">
          <button type="button" class="close" onClick={() => setAddSectionModal(false)}>
            <span aria-hidden="true">&times;</span>
          </button>
        </div>

        <div class="modal-body">
          <div class="form-main">
              <h1>Add Section</h1>

              <div class="form-group">
                <input className="form-control" name="add_section" placeholder="Section title here" onChange={e=>setSectionTitle(e.target.value)} />
              </div>

              <div className="bottom justify-content-center">
                <button className="common-btn" onClick={addsection}>Save</button>
              </div>
          </div>
        </div>
      </Modal>

      <Modal
        open={editSectionModal}
        onClose={() => setEditSectionModal(false)}
        classNames={{
          modal: `common-modal add-edit-section-modal`,
        }}
        center
      >
        <div class="modal-header">
          <button type="button" class="close" onClick={() => setEditSectionModal(false)}>
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="form-main">
              <h1>Edit Section</h1>

              <div class="form-group">
                <input className="form-control" name="edit_section" placeholder="Section title here" defaultValue={section[editSection]?.section} onChange={e=>setUpdateSectionTitle(e.target.value)}/>
              </div>

              <div className="bottom justify-content-between">
                <button className="delete-btn common-btn" onClick={()=>deleteSection(editSection)} disabled={section.length > 1 ? false : true }>Delete</button> 
                
                <button className="common-btn" onClick={()=>sectionTitleUpdated(editSection)}>Save</button>
              </div>
          </div>
        </div>
      </Modal>
    </div>
  )
}

const mapDispatchToProps = { toggleLoader, uploadCourse, getCourses, getResult };

const mapStateToProps = ({ Auth }) => {
  let { publicAddress, userStatus, availableCourses, userSoulBound, kycValue,categories } = Auth;
  return { publicAddress, userStatus, availableCourses, userSoulBound, kycValue,categories };
};

export default connect(mapStateToProps, mapDispatchToProps)(Course);
