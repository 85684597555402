const { web3 } = require('../web3');
const { env } = require('../config');

const TokenData = require(`./${env}/Token.json`);
const SubscriptionData = require(`./${env}/Subscription.json`);
const AdminSoulBoundData = require(`./${env}/AdminSoulBound.json`);
const AdminMarketpalce = require(`./${env}/Marketplace.json`);
const CertificateNFT = require(`./${env}/CertificateNFT.json`);

const networks = {
  0: 'Disconnected',
  1: 'Mainnet',
  4: 'Rinkeby',
  42: 'Kovan',
}

const TokenABI = TokenData['abi'];
const TokenAddress = TokenData['address'];
const Token = new web3.eth.Contract(TokenABI, TokenAddress);

const SubscriptionABI = SubscriptionData['abi'];
const SubscriptionAddress = SubscriptionData['address'];
const Subscription = new web3.eth.Contract(SubscriptionABI, SubscriptionAddress);

const MarketpalceABI = AdminMarketpalce['abi'];
const MarketplaceAddress = AdminMarketpalce['address'];
const Marketplace = new web3.eth.Contract(MarketpalceABI, MarketplaceAddress);

const AdminSoulBoundABI = AdminSoulBoundData['abi'];
const AdminSoulBoundBytecode = AdminSoulBoundData['bytecode'];

const CertificateNFTABI = CertificateNFT['abi'];
const CertificateNFTBytecode = CertificateNFT['bytecode'];

module.exports = {
  Token, TokenABI, TokenAddress,
  SubscriptionABI, SubscriptionAddress, Subscription,
  AdminSoulBoundABI, AdminSoulBoundBytecode,
  MarketpalceABI, MarketplaceAddress, Marketplace,
  CertificateNFTABI, CertificateNFTBytecode
};
