/* -- set app title --*/
const AppTitle = 'FRONTEND LEARN TO EARN';

/* -- set app mode -- */
// const AppMode = [''];
const AppMode = ['development'];

/* -- set API URLs --*/
const testing = 'https://www.server.codex3.courses';
const production = 'https://www.server.codex3.courses';
const development = 'https://www.server.codex3.courses';
// const development = 'http://18.220.245.61:4000';

let SocketUrl;
let env = AppMode[0] || 'development', networkId = '', message = '', explorer = '';

switch (AppMode[0]) {
  case 'development':
    networkId = 51;
    SocketUrl = development;
    explorer = 'https://apothem.blocksscan.io'
    message = 'Please switch your network to XDC Testnet';
    break;
  case 'production':
    networkId = 50;
    SocketUrl = production;
    explorer = 'https://xdc.blocksscan.io'
    message = 'Please switch your network to XDC Mainnet';
    break;
  case 'testing':
    networkId = 51;
    SocketUrl = development;
    explorer = 'https://apothem.blocksscan.io'
    message = 'Please switch your network to XDC Testnet';
    break;
  default:
    networkId = 51;
    SocketUrl = 'http://localhost:4000';
    explorer = 'https://apothem.blocksscan.io'
    message = 'Please switch your network to XDC Testnet';
}
let ApiUrl = `${SocketUrl}/api/v1`;
export { AppTitle, ApiUrl, SocketUrl, networkId, message, explorer, env };