import { PURGE } from "redux-persist";
import { setToken } from '../axios';

let initialState = {
  auth: localStorage.getItem('token'),
  publicAddress: '',
  userStatus: false,
  availableCourses: [],
  courseDetails: [],
  authorCourses: [],
  superQuizList: [],
  superQuestionsList: [],
  isLogin: localStorage.getItem('token') ? true : false,
  kycValue: [],
  userSoulBound: "",
  kycStatus: false,
  kycSubmitted: {},
  singleCourse: {},
  quizStatus:"fail",
  categories:[],
  userData: localStorage.getItem('user'),
  setLoader: { message: 'Please Wait...', status: false },
}

const Auth = (state = initialState, { type, payload }) => {
  switch (type) {
    case PURGE:
      return initialState;

    case 'SET_LOGIN_DATA':
      setToken(payload.token);
      localStorage.setItem(
        'token',
        payload.token
      );
      return {
        ...state,
        auth: payload.token,
      };

    case 'LOGIN_LOADER':
      return {
        ...state,
        isLogin: payload.status,
        setLoader: payload
      };

    case 'SET_USER':
      localStorage.setItem("user", JSON.stringify(payload));
      return {
        ...state,
        userData: payload
      };

    case 'LOGOUT':
      localStorage.removeItem('user');
      localStorage.removeItem('token');
      localStorage.removeItem('publicAddress');
      return {
        ...state,
        publicAddress: "",
        isLogin: false,
        auth:""
      };

    case 'TOGGLE_LOADER':
      return {
        ...state,
        setLoader: payload,
      };

    case 'SET_SUPER_QUIZ':
      return {
        ...state,
        superQuizList: payload,
      };

    case 'SET_SUPER_QUESTIONS':
      return {
        ...state,
        superQuestionsList: payload,
      };

    case "SET_ADDRESS":
      localStorage.setItem("publicAddress", payload);
      return {
        ...state,
        publicAddress: payload,
      };
    
    case "SET_QUIZ_STATUS":
      return {
        ...state,
        quizStatus: payload,
      };

    case "SET_CATEGORIES":
      return {
        ...state,
        categories: payload,
      };
      
    /*========== ASSESSMENT REDUCERS ============= */

    case "SET_RESULT":
      localStorage.setItem("userStatus", payload);
      return {
        ...state,
        userStatus: payload,
      };

    case "SET_COURSES":
      return {
        ...state,
        availableCourses: payload,
      };

    case "SET_COURSE":
      return {
        ...state,
        courseDetails: payload,
      };

    case "SET_SINGLE_COURSE":
      return {
        ...state,
        singleCourse: payload
      }

    case "SET_AUTHOR_COURSES":
      return {
        ...state,
        authorCourses: payload,
      };

    /*========== KYC REDUCERS ============= */

    case "SET_KYC_STATUS":
      return {
        ...state,
        kycStatus: payload,
      };

    case "SET_KYC":
      return {
        ...state,
        kycValue: payload,
      };

    case "KYC_RESPONSE":
      return {
        ...state,
        kycSubmitted: payload,
      };

    case "SET_SOUL_BOUND":
      return {
        ...state,
        userSoulBound: payload,
      };

    default:
      return state;
  }
};

export default Auth;