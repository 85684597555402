// import Sockets from './Sockets';
import { all, takeEvery } from 'redux-saga/effects';
import { register, login, getCategories, getResult, sendResult, uploadCourse, getCourses, getCourse, getAuthorCourses, getSuperQuiz, getSuperQuestions, getKycStatus, kycSubmit, getSingleCourse, updateQuiz, getQuizStatus} from './Auth';

export default function* rootSaga() {
  yield all([
    yield takeEvery('LOGIN', login),
    yield takeEvery('REGISTER', register),
    yield takeEvery('GET_RESULT', getResult),
    yield takeEvery('GET_COURSE', getCourse),
    yield takeEvery('KYC_SUBMIT', kycSubmit),
    yield takeEvery('SEND_RESULT', sendResult),
    yield takeEvery('GET_COURSES', getCourses),
    yield takeEvery('UPLOAD_COURSE', uploadCourse),
    yield takeEvery('GET_SUPER_QUIZ', getSuperQuiz),
    yield takeEvery('GET_KYC_STATUS', getKycStatus),
    yield takeEvery('GET_AUTHOR_COURSES', getAuthorCourses),
    yield takeEvery('GET_SUPER_QUESTIONS', getSuperQuestions),
    yield takeEvery('GET_SINGLE_COURSE', getSingleCourse),
    yield takeEvery('UPDATE_QUIZ', updateQuiz),
    yield takeEvery('GET_QUIZ_STATUS', getQuizStatus),
    yield takeEvery('GET_CATEGORIES', getCategories),
    
    // Sockets();
  ]);
}