import axios from 'axios';

const PINATA_API_BASE_URL = 'https://api.pinata.cloud/';

// const uploadToPinata = async (file) => {
//   const formData = new FormData();
//   formData.append('file', file);

//   try {
//     const response = await axios.post(`${PINATA_API_BASE_URL}pinning/pinFileToIPFS`, formData, {
//       headers: {
//         'Content-Type': 'multipart/form-data',
//         'Authorization': "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySW5mb3JtYXRpb24iOnsiaWQiOiIzYWUwZDgyZi1mMGEzLTRiYjUtYTk0ZS1jOGYzOGFjNGU2M2IiLCJlbWFpbCI6Im1ic2oxNDVAZ21haWwuY29tIiwiZW1haWxfdmVyaWZpZWQiOnRydWUsInBpbl9wb2xpY3kiOnsicmVnaW9ucyI6W3siaWQiOiJGUkExIiwiZGVzaXJlZFJlcGxpY2F0aW9uQ291bnQiOjF9LHsiaWQiOiJOWUMxIiwiZGVzaXJlZFJlcGxpY2F0aW9uQ291bnQiOjF9XSwidmVyc2lvbiI6MX0sIm1mYV9lbmFibGVkIjpmYWxzZSwic3RhdHVzIjoiQUNUSVZFIn0sImF1dGhlbnRpY2F0aW9uVHlwZSI6InNjb3BlZEtleSIsInNjb3BlZEtleUtleSI6ImZmZTRlNjg1MTU0YWJkMzIxODc3Iiwic2NvcGVkS2V5U2VjcmV0IjoiY2I0NDRjNGQ1M2UzM2FhMzdmZmNmZmE3MmMyYTVmNjRlZDNiNTY0ZTgwN2E3NGE0YTliZTI4Y2Q3ODg1ODZlOCIsImlhdCI6MTcwNDcwNzgyM30.WkAXQhLEu_WQO2lOcRQJtzfkCjA3qDe8RAJrV0yHlHk",
//         'pinata_api_key': `ffe4e685154abd321877`,
//         'pinata_secret_api_key': `cb444c4d53e33aa37ffcffa72c2a5f64ed3b564e807a74a4a9be28cd788586e8`,
//       },
//     });
//     return response.data.IpfsHash;
//   } catch (error) {
//     console.error('Error uploading to Pinata:', error);
//     throw error;
//   }
// };

const uploadToPinata = (file) => {
    return new Promise(async (resolve, reject)=>{
    try {
            const formData = new FormData();
            formData.append("file", file);
            const resFile = await axios({
                method: "post",
                url: "https://api.pinata.cloud/pinning/pinFileToIPFS",
                data: formData,
                headers: {
                    'Authorization': "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySW5mb3JtYXRpb24iOnsiaWQiOiIzYWUwZDgyZi1mMGEzLTRiYjUtYTk0ZS1jOGYzOGFjNGU2M2IiLCJlbWFpbCI6Im1ic2oxNDVAZ21haWwuY29tIiwiZW1haWxfdmVyaWZpZWQiOnRydWUsInBpbl9wb2xpY3kiOnsicmVnaW9ucyI6W3siaWQiOiJGUkExIiwiZGVzaXJlZFJlcGxpY2F0aW9uQ291bnQiOjF9LHsiaWQiOiJOWUMxIiwiZGVzaXJlZFJlcGxpY2F0aW9uQ291bnQiOjF9XSwidmVyc2lvbiI6MX0sIm1mYV9lbmFibGVkIjpmYWxzZSwic3RhdHVzIjoiQUNUSVZFIn0sImF1dGhlbnRpY2F0aW9uVHlwZSI6InNjb3BlZEtleSIsInNjb3BlZEtleUtleSI6IjIxOGJiMjVkM2I2NTU2ZjdiZDUzIiwic2NvcGVkS2V5U2VjcmV0IjoiYTYyZTRhZDRkNGVkOGNjMmUyYzk0ODRiZTBmNzFjMTZiNmZjZGRjZTdmN2ZlMmYyOGNkNmI3YjMyYTBiMzhkMiIsImlhdCI6MTcxMDc0ODE1M30.anv7AZfHaUyZy6DH97y_RxK1-1xwwmxxYWtX_jwe9g0",
                    'pinata_api_key': `218bb25d3b6556f7bd53`,
                    'pinata_secret_api_key': `a62e4ad4d4ed8cc2e2c9484be0f71c16b6fcddce7f7fe2f28cd6b7b32a0b38d2`,
                    "Content-Type": "multipart/form-data"
                },
            });

              const ImgHash = `https://gateway.pinata.cloud/ipfs/${resFile.data.IpfsHash}`;
              return resolve(ImgHash);
            } catch (error) {
                console.error('Error uploading to Pinata:', error);
                reject(error);
            }
        })   
};

export { uploadToPinata };
