export const register = (data) => ({
  type: 'REGISTER',
  payload: data,
});

export const login = (data) => ({
  type: 'LOGIN',
  payload: data,
});

export const setUser = (data) => ({
  type: 'SET_USER',
  payload: data,
});

export const loginLoader = data => ({
  type: 'LOGIN_LOADER',
  payload: data
});

export const setLoginData = (data) => ({
  type: 'SET_LOGIN_DATA',
  payload: data,
});

export const setQuizStatus = (data) => ({
  type: 'SET_QUIZ_STATUS',
  payload: data,
});

export const getQuizStatus = (data) => ({
  type: 'GET_QUIZ_STATUS',
  payload: data,
});

export const setCategories = (data) => ({
  type: 'SET_CATEGORIES',
  payload: data,
});

export const getCategories = (data) => ({
  type: 'GET_CATEGORIES',
  payload: data,
});


export const logout = (data) => ({
  type: 'LOGOUT',
  payload: data,
});

export const setAddress = (data) => ({
  type: 'SET_ADDRESS',
  payload: data,
});

export const toggleLoader = (data) => ({
  type: 'TOGGLE_LOADER',
  payload: data,
});

export const getSuperQuiz = (data) => ({
  type: 'GET_SUPER_QUIZ',
  payload: data,
});

export const setSuperQuiz = (data) => ({
  type: 'SET_SUPER_QUIZ',
  payload: data,
});

export const getSuperQuestions = (data) => ({
  type: 'GET_SUPER_QUESTIONS',
  payload: data,
});

export const setSuperQuestions = (data) => ({
  type: 'SET_SUPER_QUESTIONS',
  payload: data,
});

export const sendResult = (data) => ({
  type: 'SEND_RESULT',
  payload: data,
});

export const getResult = (data) => ({
  type: 'GET_RESULT',
  payload: data,
});

export const setResult = (data) => ({
  type: 'SET_RESULT',
  payload: data,
});

export const getSingleCourse = (data) => ({
  type: 'GET_SINGLE_COURSE',
  payload: data,
});

export const updateQuiz = (data) => ({
  type: 'UPDATE_QUIZ',
  payload: data,
});

export const setSingleCourse = (data) => ({
  type: 'SET_SINGLE_COURSE',
  payload: data,
});

export const uploadCourse = (data) => ({
  type: 'UPLOAD_COURSE',
  payload: data,
});

export const getCourses = () => ({
  type: 'GET_COURSES'
});

export const setCourses = (data) => ({
  type: 'SET_COURSES',
  payload: data,
});

export const getCourse = (data) => ({
  type: 'GET_COURSE',
  payload: data,
});

export const setCourse = (data) => ({
  type: 'SET_COURSE',
  payload: data,
});

export const getAuthorCourses = (data) => ({
  type: 'GET_AUTHOR_COURSES',
  payload: data,
});

export const setAuthorCourses = (data) => ({
  type: 'SET_AUTHOR_COURSES',
  payload: data,
});

export const getKycStatus = (data) => ({
  type: 'GET_KYC_STATUS',
  payload: data
});

export const setKycStatus = (data) => ({
  type: 'SET_KYC_STATUS',
  payload: data
});

export const setKyc = (data) => ({
  type: 'SET_KYC',
  payload: data
});

export const kycResponse = (data) => ({
  type: 'KYC_RESPONSE',
  payload: data
});

export const kycSubmit = (data) => ({
  type: 'KYC_SUBMIT',
  payload: data
});

export const setSoulBound = (data) => ({
  type: 'SET_SOUL_BOUND',
  payload: data
});
