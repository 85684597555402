import axios from 'axios';
import { web3 } from '../web3';
import jwt_decode from 'jwt-decode';
import EventBus from 'eventing-bus';
import { setHeader, setToken } from "../axios";
import { all, takeEvery, call, put } from 'redux-saga/effects';
import { setAddress,setQuizStatus, loginLoader, setKyc, setKycStatus, setSoulBound, kycResponse, setUser, setResult, setCourses, setCourse, setAuthorCourses, toggleLoader, setLoginData, setSuperQuiz, setSuperQuestions, setSingleCourse, setCategories } from '../actions/Auth';


/*========== ASSESSMENT FUNCTIONS =============*/

export function* register({ payload, history }) {
  const { error, response } = yield call(postCall, { path: '/registerAdmin', payload });
  if (error) EventBus.publish("error", error['response']['data']['message']);
  else if (response) {
    const decoded = jwt_decode(response['data']['body']['token']);
    if (!['admin'].includes(decoded['role'])) {
      EventBus.publish('error', "Please login through admin account!");
      return;
    }
    yield put(
      setUser(decoded)
    );
    setToken(response['data']['body']['token']);
    yield put(loginLoader({ status: false }));
    yield put(
      setLoginData({ token: response['data']['body']['token'] })
    );
    EventBus.publish('success', response['data']['message']);
    yield put(setQuizStatus(decoded['quizStatus']));
    if(decoded['quizStatus'] !== "pass"){
      setTimeout(() => window.location.href = '/select-category', 1000);
    } else {
      setTimeout(() => window.location.href = '/course', 1000);
    }
    EventBus.publish(response['data']['message']);
  }
  yield put(loginLoader({ status: false }));
}

export function* login({ payload, history }) {
  const { error, response } = yield call(postCall, { path: '/loginAdmin', payload });
  if (error) EventBus.publish("error", error['response']['data']['message']);
  else if (response) {
    const decoded = jwt_decode(response['data']['body']['token']);
    yield put(
      setUser(decoded)
    );
    if (!['admin'].includes(decoded['role'])) {
      EventBus.publish('error', "Please login through admin account!");
      return;
    }
    setToken(response['data']['body']['token']);
    yield put(loginLoader({ status: true }));
    yield put(
      setLoginData({ token: response['data']['body']['token'] })
    );
    
    if(decoded['quizStatus'] !== "pass"){
      payload.history.push("/select-category");
      // setTimeout(() => , 1000);
      window.location.href = '/select-category'
    } else {
      // payload.history.push("/course");
      // setTimeout(() => , 1000);
      window.location.href = '/course'
    }
    EventBus.publish('success', response['data']['message']);
  }
}

export function* logout() {
  yield put(setLoginData({ token: "" }));
};

export function* getSuperQuiz({payload}) {
  const { error, response } = yield call(getCall, `/getQuiz/${payload}`);
  if (error) {
    EventBus.publish("error", error['response']['data']['message']);
  }
  else if (response) {
    yield put(setSuperQuiz(response['data']['body']));
  }
  // yield put(toggleLoader({ message: "", status: false }));
};

export function* getCategories() {
  const { error, response } = yield call(getCall, "/getCategory");
  if (error) {
    EventBus.publish("error", error['response']['data']['message']);
  }
  else if (response) {
    yield put(setCategories(response['data']['body']));
  }
  // yield put(toggleLoader({ message: "", status: false }));
};

export function* getSuperQuestions({ payload }) {
  const { error, response } = yield call(getCall, `/getQuizQuestions/${payload['quizId']}`);
  if (error) {
    // yield put(toggleLoader({ status: false }));
    EventBus.publish("error", error['response']['data']['message']);
  }
  else if (response) {
    yield put(setSuperQuestions(response['data']['body']));
  }
};

export function* getSingleCourse({ payload }) {
  const { error, response } = yield call(getCall, `/getSingleCouse/${payload['id']}`);
  if (error) {
    // yield put(toggleLoader({ status: false }));
    EventBus.publish("error", error['response']['data']['message']);
  }
  else if (response) {
    yield put(setSingleCourse(response['data']['body']));
  }
  yield put(toggleLoader({ status: false }));
};

export function* updateQuiz({ payload }) {
  const { error, response } = yield call(postCall, { path: '/updateQuiz', payload });
  if (error) {
    // yield put(toggleLoader({ status: false }));
    EventBus.publish("error", error['response']['data']['message']);
  }
  else if (response) {
    // yield put(setQuizStatus(response['data']['body']['quizStatus']));
    yield put(setSingleCourse(response['data']['body']));
    EventBus.publish("success", response['data']['message']);
  }
  yield put(toggleLoader({ status: false }));
};

export function* getQuizStatus({ payload }) {
  const { error, response } = yield call(getCall, "/getUserQuiz");
  if (error) {
    // yield put(toggleLoader({ status: false }));
    // EventBus.publish("error", error['response']['data']['message']);
  }
  else if (response) {
    yield put(setQuizStatus(response['data']['body']['quizStatus']));
    // yield put(setSingleCourse(response['data']['body']));
    // EventBus.publish("success", response['data']['message']);
  }
  yield put(toggleLoader({ status: false }));
};

export function* sendResult({ payload }) {
  const { error, response } = yield call(postCall, { path: "/submitQuiz", payload });
  if (error) EventBus.publish("error", error['response']['data']['message']);
  else if (response) {
    yield put({
      type: "GET_KYC_STATUS",
      payload: {
        publicAddress: payload['publicAddress'],
      }
    });
    EventBus.publish("success", response['data']['message']);
    if (payload['status'] == "pass") {
      window.location.href = '/congratulation'
    } else {
      window.location.href = '/select-category'
    }
  };
  yield put(toggleLoader({ status: false }));
};

export function* uploadCourse({ payload }) {
  const { error, response } = yield call(postCall, { path: "/submitCourse", payload });
  if (error) EventBus.publish("error", error['response']['data']['message']);
  else if (response) {
    yield put(setCourses(response['data']['body']))
    EventBus.publish("success", response['data']['message']);
  }
  yield put(toggleLoader({ status: false }));
};

export function* getResult({ payload }) {
  const { error, response } = yield call(postCall, { path: "/getResult", payload });
  if (error) { EventBus.publish("error", error['response']['data']['message']); }
  else if (response) {
    yield put(setResult(response?.['data']?.['body']?.[0]?.['status']));
  }
};

export function* getCourses() {
  const { error, response } = yield call(getCall, "/getAllCourses");
  if (error) { 
      yield put(toggleLoader({ status: false }));
    // EventBus.publish("error", error['response']['data']['message']); 
}
  else if (response) {
    yield put(setCourses(response['data']['body']));
    yield put(toggleLoader({ status: false }));
  }
};

export function* getCourse({ payload }) {
  const { error, response } = yield call(postCall, { path: "/getCourse", payload });
  if (error) { 
    // EventBus.publish("error", error['response']['data']['message']); 
  }
  else if (response) {
    yield put(setCourse(response['data']['body']));
  }
};

export function* getAuthorCourses({ payload }) {
  const { error, response } = yield call(postCall, { path: "/getAuthorCourses", payload });
  if (error) { EventBus.publish("error", error['response']['data']['message']); }
  else if (response) {
    yield put(setAuthorCourses(response['data']['body']));
  }
};

export function* getKycStatus({ payload }) {
  const { error, response } = yield call(postCall, { path: "/kycStatus", payload });
  if (error) {
    localStorage.setItem("kycStatus","nill");
    yield put(toggleLoader({ status: false }));
    // EventBus.publish("error", error['response']['data']['message']);
  }
  else if (response) {
    localStorage.setItem("kycStatus",response['data']['body']['kycStatus']);
    yield put(setKyc(response['data']['body']));
    yield put(setKycStatus(response['data']['body']['status']));
    yield put(setSoulBound(response['data']['body']['soulbound']));
    yield put(toggleLoader({ status: false }));
  }
};

export function* kycSubmit({ payload }) {
  const { error, response } = yield call(postCall, { path: '/kycSubmit', payload });
  if (error) {
    EventBus.publish("error", error['response']['data']['message']);
  } else if (response) {
    yield put(kycResponse(response['data']['body']));
  }
  yield put(toggleLoader({ status: false }));
};

function* actionWatcher() {
};

export default function* rootSaga() {
  yield all([actionWatcher()]);
};

function postCall({ path, payload }) {
  return axios
    .post(path, payload)
    .then(response => ({ response }))
    .catch(error => {
      if (error.response.status === 401) EventBus.publish("tokenExpired");
      return { error };
    });
};

function getCall(path) {
  return axios
    .get(path)
    .then(response => ({ response }))
    .catch(error => {
      if (error.response.status === 401) EventBus.publish("tokenExpired");
      return { error };
    });
};

function deleteCall(path) {
  return axios
    .delete(path)
    .then(response => ({ response }))
    .catch(error => {
      if (error.response.status === 401) EventBus.publish("tokenExpired");
      return { error };
    });
};

function putCall({ path, payload }) {
  return axios
    .put(path, payload)
    .then(response => ({ response }))
    .catch(error => {
      if (error.response.status === 401) EventBus.publish("tokenExpired");
      return { error };
    });
};
