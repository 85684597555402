import EventBus from "eventing-bus";
import { connect } from "react-redux";
import Countdown from "react-countdown";
import OwlCarousel from "react-owl-carousel";
import React, { useEffect, useMemo, useState } from "react";
import ReactDOM from 'react-dom';
import 'react-responsive-modal/styles.css';
import { Link, useParams } from 'react-router-dom';
import { Modal } from 'react-responsive-modal';
import {CopyToClipboard} from 'react-copy-to-clipboard';

import "./index.css";
import "../../static/css/animate.css";
import "owl.carousel/dist/assets/owl.carousel.css";
import { web3 } from "../../store/web3";
import Navbar from "../../components/navbar";
import Sidebar from "../../components/sidebar";
import Footer from "../../components/footer";
import { networkId, message } from "../../store/config";
import { toggleLoader, uploadCourse, getCourses, getResult, getSingleCourse, updateQuiz } from "../../store/actions/Auth";
import { Subscription, SubscriptionAddress, AdminSoulBoundABI } from "../../store/contract/index";
const correct = "/images/correct-icon.png";
const loader = "/images/loader.png";
const course = "/images/course1.png";
const arrowleft = "/images/arrow-circle-left.png";
const arrowright = "/images/arrow-circle-right.png";

function AddQuiz(props) {
  let [courseDetailModal, setCourseDetailModal] = useState(false);
  let [mcqsSetupModal, setMcqsSetupModal] = useState(false);
  let [videoId, setVideoId] = useState("");

  const {id} = useParams();

  let [state, setState] = useState({
    sticky: false,
    quizs: [{ 
      question:"",
      answerA:"",
      answerB:"",
      answerC:"",
      answerD:"",
      correctAnswer:"",
      courseId:id
  }]
  });

  useEffect(()=>{
    props.toggleLoader({message:"Load course...", status: true});
    props.getSingleCourse({id});
  },[id])

  const onScroll = () => {
    const { pageYOffset } = window;
    if (pageYOffset > 20) {
      state['sticky'] = true;
      setState(state);
    }
    if (pageYOffset < 20) {
      state['sticky'] = false;
      setState(state);
    }
  };

  const addInputField = ()=>{
    let {quizs} = state;
    setState({quizs:[...quizs, { 
        question:"",
        answerA:"",
        answerB:"",
        answerC:"",
        answerD:"",
        correctAnswer:"",
        courseId:id
    }]})
  }

  const removeFormFields = (index)=>{
        let {quizs} = state;
        const rows = [...quizs];
        rows.splice(index, 1);
        setState({quizs:rows})
    }

    const handleChangeForm = (evnt,index)=>{
        let {quizs} = state;
        const { name, value } = evnt.target;
        const list = [...quizs];
        list[index][name] = value;
        setState({quizs:list})
    }

    const publishQuiz = () => {
      let {quizs} = state;
      if(quizs.length == 0) return EventBus.publish("error", "Please add quiz questions");
      for (let index = 0; index < quizs.length; index++) {
          const element = quizs[index];
          if(!element['question'] && !element['question'].trim("") == true) return EventBus.publish("error", `Please add question no ${index+1}`);
          if(!element['answerA'] && !element['answerA'].trim("") == true) return EventBus.publish("error", `Please add option A of question ${index+1}`);
          if(!element['answerB'] && !element['answerB'].trim("") == true) return EventBus.publish("error", `Please add option B of question ${index+1}`);
          if(!element['answerC'] && !element['answerC'].trim("") == true) return EventBus.publish("error", `Please add option C of question ${index+1}`);
          if(!element['answerD'] && !element['answerD'].trim("") == true) return EventBus.publish("error", `Please add option D of question ${index+1}`);
          if(!element['correctAnswer'] && !element['correctAnswer'].trim("") == true) return EventBus.publish("error", `Please add correct option of question ${index+1}`);
      }
      props.toggleLoader({message:"Quiz submitting...", status: true});
      props.updateQuiz({quiz:quizs,videoId,courseId:id});
  }

  
  const openModel = (videoId) =>{
    setVideoId(videoId);
    setMcqsSetupModal(true)
  }
  
  useEffect(()=>{
   setState({
    quizs: [{ 
      question:"",
      answerA:"",
      answerB:"",
      answerC:"",
      answerD:"",
      correctAnswer:"",
      courseId:id
    }]
   })
   setMcqsSetupModal(false);
  },[props.singleCourse]);

  return (
    <div className="wrapper" onWheel={onScroll}>
      <Navbar />

      <div className="admin-wrapper">
        <Sidebar />
        {props.singleCourse && Object.keys(props.singleCourse).length > 0 &&
        
        <div className="inner add-quiz-sec">
            <div className="btn-wrap">
              {/* <button className="back common-btn">Back to all courses</button> */}

              {/* <button className="common-btn">Save changes</button> */}
            </div>

            <h2>{props?.singleCourse?.course.title}</h2>

            <p>{props?.singleCourse.course?.description}</p>
            <CopyToClipboard text={props?.singleCourse?.course?.courseContractAddress}
              onCopy={() => EventBus.publish("success", `Course address copied`)}>
              <p>{props?.singleCourse?.course?.courseContractAddress}</p>
            </CopyToClipboard>
            

            <div className="all-videos">
              <h2>All videos</h2>
              {props.singleCourse.courseQuiz && props.singleCourse.courseQuiz.length > 0
              ?
              props.singleCourse.courseQuiz.map((item,index)=>
              <>
              <h4>{item['section']}</h4>
              <div className="video-box">
                  <div className="video-wrap">
                    <img src={props.singleCourse.course.courseImage}></img>
                  </div>
                  <div className="video-content">
                    {item['quiz'].length == 0 ?
                    <button onClick={() => openModel(item['_id'])} className="common-btn">
                        Add quiz
                    </button>
                    :
                    <button disabled className="common-btn">
                        Quiz added
                    </button>
                    }

                    <h3>Lecture { parseInt(index+1) }</h3>

                    {/* <span>2h 23m</span> */}
                  </div>
              </div>
              </>
              )
              :
              <div className="video-box">
                  <div className="video-wrap">
                    Video Not Found
                  </div>
              </div>
              }

            </div>
        </div>
        }
      </div>


                <Modal
                    open={mcqsSetupModal}
                    onClose={() => setMcqsSetupModal(false)}
                    classNames={{
                        modal: `common-modal mcqs-setup`,
                    }}
                    center
                    >
                <div class="modal-header">
                     <button type="button" class="close" onClick={() => setMcqsSetupModal(false)}>
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>

                <div class="modal-body">
                    <div class="form-main">
                            <div class="inner">
                                    <div className="head">
                                        <div className="empty"></div>

                                        <div className="title-wrap">
                                            <h1>Add MCQs</h1>

                                            <p>Select correct option before adding question to the quiz.</p>
                                        </div>

                                        <button className="common-btn" onClick={()=>publishQuiz()}>Publish quiz</button>
                                    </div>
                                    <div className="row-wrap">
                                    {state.quizs.map((data, index)=>{
                                    const {question,answerA, answerB, answerC, answerD, correctAnswer}= data;
                                    return(
                                        <div className="row" key={index}>
                                             <div class="form-group col-12">
                                                            <h5 style={{color:"white"}}>Question No {index+1}</h5>
                                                </div>
                                            <div class="form-group col-12">
                                                <textarea className="form-control" name="question" value={question || ""} onChange={(e)=>handleChangeForm(e,index)} placeholder="Write question here"></textarea>
                                            </div>

                                            <div class="form-group col-md-6">
                                                <label for="A">A</label>
                                                <input id="A" type="text" class="form-control" placeholder="Write option A here" name="answerA" value={answerA || ""} onChange={(e)=>handleChangeForm(e,index)}/>
                                            </div>

                                            <div class="form-group col-md-6">
                                                <label for="B">B</label>
                                                <input id="B" type="text" class="form-control" placeholder="Write option B here" name="answerB" value={answerB || ""} onChange={(e)=>handleChangeForm(e,index)}/>
                                            </div>

                                            <div class="form-group col-md-6">
                                                <label for="C">C</label>
                                                <input id="C" type="text" class="form-control" placeholder="Write option C here" name="answerC" value={answerC || ""} onChange={(e)=>handleChangeForm(e,index)}/>
                                            </div>

                                            <div class="form-group col-md-6">
                                                <label for="D">D</label>
                                                <input id="D" type="text" class="form-control" placeholder="Write option D here" name="answerD" value={answerD || ""} onChange={(e)=>handleChangeForm(e,index)}/>
                                            </div>

                                            <div class="form-group col-md-6">
                                                <label for="E"><img className="blue" src={correct}/></label>
                                                <select id="E" class="form-control" name="correctAnswer" value={correctAnswer || ""} onChange={(e)=>handleChangeForm(e,index)}>
                                                    <option value="" selected disabled>Select correct option</option>
                                                    <option value={answerA}>{answerA}</option>
                                                    <option value={answerB}>{answerB}</option>
                                                    <option value={answerC}>{answerC}</option>
                                                    <option value={answerD}>{answerD}</option>
                                                </select>
                                            </div>
                                            <div class="form-group col-md-6 d-flex justify-content-end align-items-center">
                                                {(state.quizs.length !== 1) ? <button className="delete-btn common-btn"  onClick={()=>removeFormFields(index)}>Delete question</button>:""}
                                            </div>
                                        </div>
                                    )
                                    })}
                                    </div>

                                    <div className="bottom flex-center justify-content-center">
                                        <button className="common-btn" onClick={addInputField}>Add question</button>
                                    </div>
                            </div>
                    </div>
                </div>
                </Modal>
    </div>
  )
}

const mapDispatchToProps = { toggleLoader, uploadCourse, getCourses, getResult, getSingleCourse,updateQuiz };

const mapStateToProps = ({ Auth }) => {
  let { publicAddress, userStatus, availableCourses, userSoulBound, kycValue,singleCourse } = Auth;
  return { publicAddress, userStatus, availableCourses, userSoulBound, kycValue,singleCourse };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddQuiz);
