import zxcvbn from 'zxcvbn';
import validator from 'validator';
import EventBus from "eventing-bus";
import { connect } from "react-redux";
import React, { Component } from "react";
import { Redirect } from "react-router-dom";

import "./index.css";
import "../../static/css/animate.css";
import "owl.carousel/dist/assets/owl.carousel.css";

import Navbar from "../../components/navbar";
import { toggleLoader, register } from "../../store/actions/Auth";

class SignUp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sticky: false,
      name: "",
      email: "",
      type: 'password',
      score: 'null',
      password1: "",
      password2: "",
    };
    this.showHide = this.showHide.bind(this);
    this.passwordStrength = this.passwordStrength.bind(this);
  }

  showHide(e) {
    e.preventDefault();
    e.stopPropagation();
    this.setState({
      type: this.state.type === 'input' ? 'password' : 'input'
    })
  }

  onScroll = () => {
    const { pageYOffset } = window;
    if (pageYOffset > 20) this.setState({ sticky: true });
    if (pageYOffset < 20) this.setState({ sticky: false });
  };

  handleChange = (e) => {
    if (e.target.name == "name") {
      this.setState({ name: e.target.value });
    } else if (e.target.name == "email") {
      this.setState({ email: e.target.value });
    } else if (e.target.name == "password1") {
      if (e.target.value === '') {
        this.setState({
          score: 'null',
          password1: ''
        })
      }
      else {
        var pw = zxcvbn(e.target.value);
        this.setState({
          score: pw.score,
          password1: e.target.value
        });
      }
    } else if (e.target.name == "password2") {
      this.setState({ password2: e.target.value });
    }
  };

  passwordStrength(e) {
    if (e.target.value === '') {
      this.setState({
        score: 'null'
      })
    }
    else {
      var pw = zxcvbn(e.target.value);
      this.setState({
        score: pw.score
      });
    }
  }

  submitRegistration = (e) => {
    try {
      console.log("**********");
      e.preventDefault();

      let { name, email, password1, password2 } = this.state;

      if (name == "") {
        EventBus.publish("error", `Please enter name!`);
        return;
      }

      if (!name.replace(/\s/g, '').length) {
        EventBus.publish("error", `Please enter name`);
        return;
      }

      if (!name.match(/[a-zA-Z]/)) {
        EventBus.publish("error", `Name must contain alphabets`);
        return;
      }

      if (email == "") {
        EventBus.publish("error", `Please enter email!`);
        return;
      }

      if (!email.replace(/\s/g, '').length) {
        EventBus.publish("error", `Please enter email!`);
        return;
      }

      email = email.toLowerCase();
      let isValis = validator.isEmail(email);
      if (!isValis) return EventBus.publish("error", "Email is invalid");

      if (password1 == "") {
        EventBus.publish("error", `Please enter password!`);
        return;
      }

      if (!password1.replace(/\s/g, '').length) {
        EventBus.publish("error", `Please enter password`);
        return;
      }

      if (!password1.match(/[a-zA-Z]/)) {
        EventBus.publish("error", `Password must contain alphabets`);
        return;
      }

      if (password1.length <= 7) {
        EventBus.publish("error", `Password must be atleast 8 characters!`);
        return;
      }

      if (password2 == "") {
        EventBus.publish("error", `Please retype password!`);
        return;
      }

      if (password1 !== password2) {
        EventBus.publish("error", `Passwords donot match!`);
        return;
      }

      this.props.toggleLoader({status: true, message:"Registration in progress..."});

      this.props.register({ name, email, password: password1 });

    } catch (error) {
      EventBus.publish("error", `Registration Failed!`);
      console.log("error", error)
      return;
    }
  }


  render() {
    let { name, email, password1, type, score, password2, sticky } = this.state;
    let { auth } = this.props;

    // if(auth) return <Redirect to="select-category" />
    
    return (
      <div className="wrapper" onWheel={this.onScroll}>
        {/* <Navbar sticky={sticky} /> */}

        <section class="form-main sign-up">
          <div class="wrap">
            <div class="inner">
              <h1>Sign up</h1>

              <form onSubmit={this.submitRegistration}>
                <div class="form-group">
                  <input
                    class="form-control"
                    type="text"
                    name="name"
                    defaultValue={name}
                    placeholder="Name"
                    onChange={this.handleChange}
                  />
                </div>

                <div class="form-group">
                  <input
                    class="form-control"
                    type="text"
                    name="email"
                    defaultValue={email}
                    placeholder="Email"
                    onChange={this.handleChange}
                  />
                </div>

                <div class="form-group">
                  <input
                    class="form-control password"
                    type={type}
                    name="password1"
                    defaultValue={password1}
                    placeholder="Password"
                    onChange={this.handleChange}
                  />
                  <span className="password__strength" data-score={score} />
                </div>

                <div class="form-group text-right">
                  <input
                    class="form-control"
                    type={type}
                    name="password2"
                    defaultValue={password2}
                    placeholder="Retype Password"
                    onChange={this.handleChange}
                  />
                  <span className="password__show" onClick={this.showHide}>{this.state.type === 'input' ? 'Hide Password' : 'Show Password'}</span>
                </div>

                <div class="form-group">
                  <input type="submit" value="Sign up" name="submit" />
                </div>
              </form>

              <p>Already have an account? <a href="/sign-in">Sign in</a></p>
            </div>
          </div >
        </section >
      </div >
    );
  }
}

const mapDispatchToProps = { toggleLoader, register };

const mapStateToProps = ({ Auth }) => {
  let { auth } = Auth;
  return { auth };
};

export default connect(mapStateToProps, mapDispatchToProps)(SignUp);
