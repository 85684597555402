import EventBus from "eventing-bus";
import { connect } from 'react-redux';
import React, { Component } from 'react';
import Error from '@material-ui/icons/Error';
import { createBrowserHistory } from "history";
import { ToastContainer, toast } from 'react-toastify';
import CheckCircle from '@material-ui/icons/CheckCircle';
import { BrowserRouter as Router, Switch, Route, Redirect, withRouter } from "react-router-dom";
import jwt_decode from 'jwt-decode';
import Learn2Earn from './Learn2Earn';
import Course from './Learn2Earn/course.js';
import AddQuiz from './Learn2Earn/add-quiz.js';
import VerifyKYC from './Learn2Earn/verify-kyc.js';
import QuizQuestion from './Learn2Earn/quiz-question.js';
import SignIn from './Learn2Earn/sign-in.js';
import SignUp from './Learn2Earn/sign-up.js';
import SelectCategory from './Learn2Earn/select-category.js';
import Congratulation from './Learn2Earn/congratulation.js';
import Statistics from './Learn2Earn/statistics';

import { web3 } from "../store/web3";
import Loader from '../components/loader';
import PrivateRoute from '../store/PrivateRoute';
import { networkId, message } from "../store/config";
import { logout, login, getQuizStatus, getCategories, toggleLoader} from '../store/actions/Auth';

import '../static/css/style.css';

import 'jquery/dist/jquery.min.js';
import 'bootstrap/dist/js/bootstrap.min.js';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'font-awesome/css/font-awesome.min.css';
import "react-toastify/dist/ReactToastify.css";

const hist = createBrowserHistory();
class App extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isAuthenticated: this.props.isLogin,
      redirectTo: false
    }
  };

  componentDidMount() {
    if(this.props.auth){
      this.props.toggleLoader({message:"Check user status", status: true});
      this.props.getCategories()
      this.props.getQuizStatus()
      this.verifyTokenExpire()
    }
    this.checkEthereum();
    EventBus.on('tokenExpired', () => this.props.logout());
    EventBus.on('info', (e) => toast.info(() => <div> <Error /> {e}</div>));
    EventBus.on('error', (e) => toast.error(() => <div> <Error /> {e}</div>));
    EventBus.on('success', (e) => toast.success(() => <div> <CheckCircle /> {e}</div>));
  };

  checkEthereum = async () => {
    if (typeof window.ethereum !== "undefined") {

      // check network
      web3.eth.net.getId((err, netId) => {
        if (netId != networkId) {
          EventBus.publish('info', message);
        }
      });

      window.ethereum.on("accountsChanged", accounts => {
        this.props.logout();
        window.location.reload();
        EventBus.publish("info", "Account has been changed");
      });

      window.ethereum.on("networkChanged", netId => {
        this.props.logout();
        window.location.reload();
        EventBus.publish("info", "Network has been changed");
      });
    }
  };

  verifyTokenExpire = () => {
    const decoded = jwt_decode(this.props.auth);
    const currentDate = new Date();
    const expirationTimestamp = decoded.exp * 1000;
    if (currentDate.getTime() > expirationTimestamp) {
      this.setState({isAuthenticated:false})
    } else {
      this.setState({isAuthenticated:true})
    }
  }

  componentDidUpdate(prevProps,prevState){
    if(prevProps.auth !== this.props.auth){
      if(this.props.auth){
        this.verifyTokenExpire()
      }
    }
  }

  render() {
    let {isAuthenticated,redirectTo} = this.state;
    let {quizStatus} = this.props;

    return (
      <div className="">
        <Loader />
        <ToastContainer
          closeOnClick
          position="bottom-left"
        />

        <Router history={hist}>
          {quizStatus == "pass" ?
          <Switch>
            <Route exact path="/" render={props => !isAuthenticated ? <SignIn {...props} /> :  <Course {...props} /> } />
            <Route exact path="/sign-in" render={props => !isAuthenticated ? <SignIn {...props} /> :  <Course {...props} /> } />
            <Route exact path='/sign-up' render={props =>  !isAuthenticated ? <SignUp {...props} /> :  <Course {...props} /> } />
            {/* <Route exact path='/verify-kyc' render={props => !isAuthenticated ? <VerifyKYC {...props} /> : <Course {...props} />} /> */}
            {/* <PrivateRoute exact path='/select-category' component={props => <SelectCategory {...props} />} isAuthenticated={isAuthenticated} /> */}
            <PrivateRoute exact path='/course' component={props => <Course {...props} />} isAuthenticated={isAuthenticated}  />
            <PrivateRoute exact path='/add-quiz/:id' component={props => <AddQuiz {...props} />} isAuthenticated={isAuthenticated}  />
            {/* <PrivateRoute exact path='/quiz/:category' component={props => <QuizQuestion {...props} />} isAuthenticated={isAuthenticated}  /> */}
            {/* <PrivateRoute exact path='/congratulation' component={props => <Congratulation {...props} />} isAuthenticated={isAuthenticated}  /> */}
            <PrivateRoute exact path='/statistics' component={props => <Statistics {...props} />}  isAuthenticated={isAuthenticated}  />
            <Redirect from="*" to="/" />
          </Switch>
          :
          <Switch>
            <Route exact path="/" render={props => !isAuthenticated ? <SignIn {...props} /> :  <SelectCategory {...props} /> } />
            <Route exact path="/sign-in" render={props => !isAuthenticated ? <SignIn {...props} /> :  <SelectCategory {...props} /> } />
            <Route exact path='/sign-up' render={props =>  !isAuthenticated ? <SignUp {...props} /> :  <SelectCategory {...props} /> } />
            <Route exact path='/verify-kyc' render={props => !isAuthenticated ? <VerifyKYC {...props} /> : <Course {...props} />} />
            <PrivateRoute exact path='/select-category' component={props => <SelectCategory {...props} />} isAuthenticated={isAuthenticated} />
            {/* <PrivateRoute exact path='/course' component={props => <Course {...props} />} isAuthenticated={isAuthenticated}  /> */}
            {/* <PrivateRoute exact path='/add-quiz/:id' component={props => <AddQuiz {...props} />} isAuthenticated={isAuthenticated}  /> */}
            <PrivateRoute exact path='/quiz/:category' component={props => <QuizQuestion {...props} />} isAuthenticated={isAuthenticated}  />
            <PrivateRoute exact path='/congratulation' component={props => <Congratulation {...props} />} isAuthenticated={isAuthenticated}  />
            <PrivateRoute exact path='/statistics' component={props => <Statistics {...props} />}  isAuthenticated={isAuthenticated}  />
            <Redirect from="*" to="/" />
          </Switch>
        }
        </Router>

      </div>
    );
  }
}

const mapDispatchToProps = {
  logout, login, getQuizStatus, getCategories, toggleLoader
};

const mapStateToProps = ({ Auth }) => {
  let { publicAddress, isLogin, auth, quizStatus, categories} = Auth;
  return { publicAddress, isLogin, auth, quizStatus, categories }
};

export default connect(mapStateToProps, mapDispatchToProps)(App);